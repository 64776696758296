import React from 'react';
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import { Constants } from '../utils/constants';
import { randomID } from '../utils/utils';

// local storage

const localStorageEffect = (key, defaultValue) => ({ setSelf, onSet }) => {
  // Load the saved value from localStorage
  const savedValue = localStorage.getItem(key);

  // Use the saved value if it exists, otherwise use the default value
  setSelf(savedValue != null ? savedValue : defaultValue);

  // This function is called whenever the atom's state changes
  onSet(newValue => {
    if (newValue === undefined) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, newValue);
    }
  });
};

// sort order

export const snippetsSortOrderState = atom({
  key: 'snippetsSortOrderState',
  // Set the default state to null or another value as needed
  default: Constants.SORT_ORDER_ASC,
  // Add the effect to connect this atom with localStorage
  effects_UNSTABLE: [
    localStorageEffect(Constants.LocalStorage.SNIPPETS_SORT_ORDER, Constants.SORT_ORDER_ASC)
  ],
});

export const snippetsSortOrderDynamicStateForIndex = (index) => {
  return atom({
    key: Constants.LocalStorage.SNIPPETS_SORT_ORDER_DYNAMIC(index),
    default: Constants.SORT_ORDER_ASC,
    // Add the effect to connect this atom with localStorage
    effects_UNSTABLE: [
      localStorageEffect(Constants.LocalStorage.SNIPPETS_SORT_ORDER_DYNAMIC(index), Constants.SORT_ORDER_ASC)
    ],
  });
}

// sort option

export const snippetsSortOptionState = atom({
  key: 'snippetsSortOptionState',
  // Set the default state to null or another value as needed
  default: Constants.SORT_OPTION_BY_DATE,
  // Add the effect to connect this atom with localStorage
  effects_UNSTABLE: [
    localStorageEffect(Constants.LocalStorage.SNIPPETS_SORT_OPTION, Constants.SORT_OPTION_BY_DATE)
  ],
});

export const snippetsSortOptionDynamicStateForIndex = (index) => {
  return atom({
    key: Constants.LocalStorage.SNIPPETS_SORT_OPTION_DYNAMIC(index),
    default: Constants.SORT_OPTION_BY_DATE,
    // Add the effect to connect this atom with localStorage
    effects_UNSTABLE: [
      localStorageEffect(Constants.LocalStorage.SNIPPETS_SORT_OPTION_DYNAMIC(index), Constants.SORT_OPTION_BY_DATE)
    ],
  });
}

// observed user snippets state from local storage
export const localUserSnippetsState = atom({
  key: 'localUserSnippetsState',
  default: null,
  // Add the effect to connect this atom with localStorage
  // effects_UNSTABLE: [
  //   localStorageEffect('snippets', {})
  // ],
});

// add classroom snippet to my snippets state

export const addingSnippetKeysState = atom({
  key: 'addingSnippetKeysState',
  default: {},
});

// parsed dynamic layout component state

export const dynamicLayoutComponentDataState = atom({
  key: 'dynamicLayoutComponentDataState',
  default: {},
});

export const snippetsNeedUpdateState = atom({
  key: 'snippetsNeedUpdateState',
  default: null
});

export function setUserSnippetsNeedUpdate(setter) {
  setter(randomID());
}

// snippets list state
export const snippetsState = atom({
  key: 'snippetsState',
  default: null,
});

export const layoutState = atom({
  key: 'layoutSnippetsState',
  default: {}
});

export const filterSnippetsTextState = atom({
  key: 'filterSnippetsTextState',
  default: '',
});

export const showSnippetsModalState = atom({
  key: 'showSnippetsModalState',
  default: { isShowing: false, editSnippetID: null, title: null, classroomID: null, models: null, selectedModelID: null, type: null, definitionsLanguageCode: null },
});

export function showAddEditSnippetModal(
  setter,
  editSnippetID,
  pinned_at,
  title,
  classroomID,
  models,
  selectedModelID,
  plainTextTerm,
  plainTextTranslation,
  searchTerm,
  definitionsLanguageCode,
  type) {

  let dict = {
    isShowing: true,
    editSnippetID, 
    pinned_at,
    title, 
    classroomID, 
    models, 
    selectedModelID, 
    type, 
    plainTextTerm, 
    plainTextTranslation, 
    definitionsLanguageCode,
    searchTerm
  };

  console.log('Show snippet modal dict ' + JSON.stringify(dict));

  setter(dict)
}

export function hideAddEditSnippetModal(setter) {
  let dict = { isShowing: false }
  setter(dict)
}

// when we're adding a snippet, we can specify the language for which we're adding the snippet
export const addSnippetModalLanguage = atom({
  key: 'addSnippetModalLanguage',
  default: null,
});

export const snippetTitleFormatState = atom({
  key: 'snippetTitleFormatState',
  default: '${term} - ${translation}'
});

export const snippetTextFromFormat = (term, translation, format) => {
  return format.replace('${term}', term).replace('${translation}', translation);
}
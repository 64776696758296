import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Center, Flex, IconButton, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from "react-icons/io";

let colorText = 'white'
let colorSelected = 'purple.500'

const AdminClickableText = ({ sentences, onSelectMappings }) => {
  const [selectedWords, setSelectedWords] = useState([]);

  function nonClickableText(text, sentenceIndex, wordIndex) {
    return (
      <Box
        as="span"
        key={`${sentenceIndex}-${wordIndex}`}
        bg={colorSelected}
        color={colorText}
        borderRadius="md"
      >
        {text}
      </Box>
    )
  }

  const handleClick = (sentenceIndex, wordIndex) => {
    const selectedWord = { sentenceIndex, wordIndex };
    const isAlreadySelected = selectedWords.some(
      (word) =>
        word.sentenceIndex === sentenceIndex && word.wordIndex === wordIndex
    );
  
    let updatedSelections;
  
    if (isAlreadySelected) {
      // Deselect the word
      updatedSelections = selectedWords.filter(
        (word) =>
          !(word.sentenceIndex === sentenceIndex && word.wordIndex === wordIndex)
      );
    } else {
      // Ensure selection is limited to one sentence
      if (selectedWords.length === 0 || selectedWords[0].sentenceIndex === sentenceIndex) {
        updatedSelections = [...selectedWords, selectedWord];
      } else {
        return; // Ignore the selection if it's from a different sentence
      }
    }
  
    setSelectedWords(updatedSelections);
  
    const selectedMappings = updatedSelections.map(({ sentenceIndex, wordIndex }) =>
      sentences[sentenceIndex].mappings[wordIndex]
    );
  
    onSelectMappings?.(selectedMappings);
  };
    
  const renderText = () => {
    let renderedText = [];

    sentences.forEach((sentence, sentenceIndex) => {
      if (sentence.text) {
        let currentIndex = 0;
        const { text, mappings } = sentence;

        mappings.forEach((mapping, wordIndex) => {
          const { text: word, range } = mapping;

          if (currentIndex < range.location) {
            const nonMappedText = text.slice(currentIndex, range.location);
            renderedText.push(nonClickableText(nonMappedText, sentenceIndex, wordIndex));
          }

          const isSelected = selectedWords.some(
            (word) =>
              word.sentenceIndex === sentenceIndex && word.wordIndex === wordIndex
          );

          renderedText.push(
            <Box
              as="span"
              key={`${sentenceIndex}-${wordIndex}`}
              onClick={() => handleClick(sentenceIndex, wordIndex)}
              cursor="pointer"
              bg={isSelected ? "white" : "purple.500"}
              color={isSelected ? "purple.500" : "white"}
              borderRadius="md"
            >
              {word}
            </Box>
          );

          currentIndex = range.location + range.length;
        });

        if (currentIndex < text.length) {
          const remainingText = text.slice(currentIndex);
          renderedText.push(nonClickableText(remainingText, sentenceIndex, 0));
        }
      }

      if (sentence.new_line) {
        renderedText.push(<br key={`newline-${sentenceIndex}`} />);
      }

      renderedText.push(' ');
    });

    return renderedText;
  };


  return (
    <Box>
      {renderText()}
    </Box>
  );
};


const ClickableText = ({ sentences, onSelectMapping }) => {
  const [selectedWordIndex, setSelectedWordIndex] = useState(null);

  const handleClick = (sentenceIndex, wordIndex) => {
    const isSelected = selectedWordIndex?.sentenceIndex === sentenceIndex && selectedWordIndex?.wordIndex === wordIndex;
    const newSelection = isSelected ? null : { sentenceIndex, wordIndex };
    setSelectedWordIndex(newSelection);
    const mapping = newSelection ? sentences[sentenceIndex].mappings[wordIndex] : null;
    onSelectMapping?.(mapping);
  };

  const renderText = () => {
    let renderedText = [];

    function nonClickableText(text, sentenceIndex, wordIndex) {
      return (
        <Box
          as="span"
          key={`${sentenceIndex}-${wordIndex}`}
          bg={colorSelected}
          color={colorText}
          borderRadius="md"
        >
          {text}
        </Box>
      )
    }

    sentences.forEach((sentence, sentenceIndex) => {
      if (sentence.text) {
        let currentIndex = 0;
        const { text, mappings } = sentence;

        mappings.forEach((mapping, wordIndex) => {
          const { text: word, range } = mapping;

          if (currentIndex < range.location) {
            const nonMappedText = text.slice(currentIndex, range.location);
            renderedText.push(nonClickableText(nonMappedText, sentenceIndex, wordIndex));
          }

          const isSelected = selectedWordIndex?.sentenceIndex === sentenceIndex && selectedWordIndex?.wordIndex === wordIndex;
          renderedText.push(
            <Box
              as="span"
              key={`${sentenceIndex}-${wordIndex}`}
              onClick={() => handleClick(sentenceIndex, wordIndex)}
              cursor="pointer"
              bg={isSelected ? colorText : colorSelected}
              color={isSelected ? colorSelected : colorText}
              borderRadius="md"
            >
              {word}
            </Box>
          );

          currentIndex = range.location + range.length;
        });

        if (currentIndex < text.length) {
          const remainingText = text.slice(currentIndex);
          renderedText.push(nonClickableText(remainingText, 10000, 10000));
        }

        // add space
        renderedText.push(' ');
      }

      if (sentence.new_line) {
        renderedText.push(<br key={`newline-${sentenceIndex}`} />);
      }
    });

    return renderedText;
  };

  return (
    <Box>
      {renderText()}
    </Box>
  );
};

function StoryDetails({ story, onSelectMapping, adminMode }) {
  const [storyLevel, setStoryLevel] = useState(null);
  const [canGoBackToStories, setCanGoBackToStories] = useState(false);
  const { t } = useTranslation()

  const navigate = useNavigate()

  useEffect(() => {
    if (story) {
      setStoryLevel(`${story.level} ${t('story.level')}`);
    } else {
      setStoryLevel(null);
    }
  }, [story]);

  const onBackToStoriesClicked = () => {
    navigate('/stories');
  }

  const headerRef = useRef(null);

  return (
    <Box data-test='story-details' w='100%' h='100%'>
      {story && (
        <Box
          data-test='story-header'
          borderRadius='md'
          backgroundColor='rgba(255, 255, 255, 0.1)'
          h='100%'
        >
          <Flex direction='row' alignContent='center'
              borderRadius='md'
              alignItems='center'
              backgroundColor='rgba(255, 255, 255, 0.1)'>
            <Button ml={2} _hover={{ backgroundColor: "purple", color: "white" }} leftIcon={<IoMdArrowRoundBack />} backgroundColor='transparent' borderWidth={1} borderColor='gray.600' color="white" size="lg" data-test='filters-button' onClick={onBackToStoriesClicked}>
              {t("story.back_to_stories")}
            </Button>
            <Flex
              p={1}
              w='100%'
              direction='column'
              alignContent='center'
              alignItems='center'
              ref={headerRef}
            >
              <Text fontWeight='semibold' fontSize='xl' color='gray.100'>
                {story.title}
              </Text>
              <Text fontSize='sm' color='gray.300'>
                {storyLevel}
              </Text>
              {/* add tags here */}
            </Flex>
            <Button opacity={0} _hover={{ backgroundColor: "purple", color: "white" }} leftIcon={<IoMdArrowRoundBack />} backgroundColor='lightPurple' borderWidth={1} borderColor='gray.600' color="white" size="lg" data-test='filters-button' cursor='default'>
              {t("story.back_to_stories")}
            </Button>
          </Flex>


          {headerRef?.current && <Box px={4} pt={4} h={`calc(100% - 64px)`} overflowY="auto">
            {adminMode ? <AdminClickableText
              sentences={story.sentences}
              onSelectMappings={(mappings) => {
                onSelectMapping?.(mappings);
              }} />
              : 
            <ClickableText
              sentences={story.sentences}
              onSelectMapping={(mapping) => {
                onSelectMapping?.(mapping);
              }}
            />
            }
          </Box>}
        </Box>
      )}
    </Box>
  );
}

export default StoryDetails;
import {
  Button,
  Input,
  Box,
  Select,
  Text,
  RadioGroup,
  Stack,
  Radio,
  HStack,
  VStack,
  useToast,
  Flex,
  Divider,
} from "@chakra-ui/react";
import SnippetTable from "./snippet-table";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { learningLanguageState, userLanguageState } from "../state/user-state";
import { dictionarySearch } from "../api/translation.service";
import { convertDictionarySearchResultToSnippetModels, createSnippetDictionary } from "../utils/snippet-utils";
import SnippetHeader from "./snippet-header";
import { handleEnterKeyPress } from "../utils/helper";
import { copyJSONObject, isDefined } from "../utils/utils";
import {
  snippetTextFromFormat,
  snippetTitleFormatState,
} from "../state/snippets-state";
import {
  logEvent_AddSnippet_SearchDictionaryError,
  logEvent_AddSnippet_SearchDictionarySuccess,
} from "../analytics/analytics";
import { AddEditSnippetMode } from "./snippet-modal";
import DictionarySearchResults, { DictionarySearchSelection } from "../components/dictionary-search-result";
import { convertDefinitionsDictionaryToArray } from "../utils/snippet-wiktionary-parsing";

const OPACITY_NOT_SELECTED = 0.4;

function SnippetModalContentCreate({
  modalFooterHeight,
  modalTitleHeight,
  maxModalHeight,
  onLoading,
  onModelChanged,
  onEnterKeyPress,
  editModel,
  setSearchModel,
  addSnippetMode,
  setAddSnippetMode,
}) {
  const snippetStackRef = useRef(null);
  const [snippetStackHeight, setSnippetStackHeight] = useState(null);
  const snippetTableHeight = `calc(${maxModalHeight} - ${snippetStackHeight}px - ${modalFooterHeight}px - ${modalTitleHeight}px - 180px)`;

  const [learningLanguage, setLearningLanguage] = useRecoilState(
    learningLanguageState
  );
  const [userLanguage, setUserLanguage] = useRecoilState(userLanguageState);
  const [snippetTextFormat, setSnippetTextFormat] = useRecoilState(
    snippetTitleFormatState
  );

  // State:

  // Snippet
  const [snippetPinnedAt, setSnippetPinnedAt] = useState(null);
  const [editSnippetID, setEditSnippetID] = useState(null);

  // Plain text
  const [plainTextTerm, setPlainTextTerm] = useState("");
  const [plainTextTranslation, setPlainTextTranslation] = useState("");

  // generated models
  const [plainTextModel, setPlainTextModel] = useState(null);
  const [dictionaryModel, setDictionaryModel] = useState(null);

  // Search
  const [searchText, setSearchText] = useState("");
  // const [inputModels, setInputModels] = useState(null);

  const [searchTermTranslation, setSearchTermTranslation] = useState(null);
  // const [searchHeaderText, setSearchHeaderText] = useState("");
  const [definitions, setDefinitions] = useState([]);
  const [userDefinition, setUserDefinition] = useState("");
  const [selectedDefinition, setSelectedDefinition] = useState(null);

  const [selectedSearchModelID, setSelectedSearchModelID] = useState(null);
  const [selectedDefinitionID, setSelectedDefinitionID] = useState(null);
  const [selectedDefinitionCustomTranslation, setSelectedDefinitionCustomTranslation] = useState(null);
  const [selectedSections, setSelectedSections] = useState(null);

  // const [searchModel, setSearchModel] = useState(editModel); // search model received from backend
  // const [editedSearchModels, setEditedSearchModels] = useState(inputModels); // edited search model with filtered sections
  const [searchModelHasData, setSearchModelHasData] = useState(false);

  const [isSearching, setSearching] = useState(false);

  const [searchModels, setSearchModels] = useState(null);

  const { t } = useTranslation();
  const toast = useToast();

  useEffect(() => {
    console.log('edit model passed ' + JSON.stringify(editModel))
    // setInputModels(editModel?.models);
    setEditSnippetID(editModel?.editSnippetID)
    setSearchModels(editModel?.models);
    setSearchText(editModel?.searchText);
    setPlainTextTerm(editModel?.plainTextTerm);
    setPlainTextTranslation(editModel?.plainTextTranslation);
    setSelectedSearchModelID(editModel?.selectedModelID);
    setSnippetPinnedAt(editModel?.pinned_at);

    if (editModel?.plainTextTerm || editModel?.plainTextTranslation) {
      setAddSnippetMode(AddEditSnippetMode.PLAIN_TEXT);
    } else {
      setAddSnippetMode(AddEditSnippetMode.FROM_DICTIONARY);
    }
  }, [editModel])

  // useEffect(() => {
  //   // selected model changed; update definitions
  //   let definitions = []
  //   let selectedDefinition = null

  //   if (selectedSearchModelID) {
  //     let model = searchModels.find((m) => m.id === selectedSearchModelID);
  //     if (model) {
  //       let definitionsDict = JSON.parse(JSON.stringify(model?.definitions ?? {}))
  //       definitions = convertDefinitionsDictionaryToArray(definitionsDict)
  //       selectedDefinition = definitions[0]
  //     }
  //   }
  //   setSelectedDefinition(selectedDefinition);
  //   setDefinitions(definitions);
  // }, [selectedSearchModelID, searchModels])

  // useEffect(() => {
  //   if (editModel) {
  //     setAddSnippetMode(AddEditSnippetMode.FROM_DICTIONARY);
  //   } else if (editTerm || editTranslation) {
  //     setAddSnippetMode(AddEditSnippetMode.PLAIN_TEXT);
  //   }
  // }, [editModel, editTerm, editTranslation]);

  // useEffect(() => {
  //   console.log('admock load')
  //   fetch("/mock_data/mock_dictionary_search_result.json").then(async (searchResultPlainText) => {
  //     console.log('admock loaded ' + searchResultPlainText)
  //     return searchResultPlainText.json().then((searchResult) => {
  //       console.log('admock JSON ' + JSON.stringify(searchResult))
  //       let s = convertDictionarySearchResultToSnippetModels(searchResult, learningLanguage.code, true)
  //       console.log('admock snippets ' + JSON.stringify(s))
  //       // setResultSnippets(s);

  //       // setSearchTermModel(searchResult);
  //       setSearchModels(s)
  //     })
  //   })
  // }, [learningLanguage])

  // useEffect(() => {
  // input model changed
  // console.log('Input model changed ' + JSON.stringify(inputModels))
  // if (inputModels) {
  //   // editing
  //   setPlainTextTerm(inputModels?.plainTextTerm ?? '');
  //   setPlainTextTranslation(inputModels?.plainTextTranslation ?? '');
  //   setSearchText('')
  // } else {
  //   // adding
  //   setPlainTextTerm('');
  //   setPlainTextTranslation('')
  //   setSearchText('')
  // }
  // }, [inputModels])

  useEffect(() => {
    // console.log('plain text ' + plainText)
    let trimmedTerm = plainTextTerm?.trim();
    let trimmedTranslation = plainTextTranslation?.trim();
    if (!trimmedTerm || !trimmedTranslation) {
      setPlainTextModel(null);
    } else {
      let text = snippetTextFromFormat(
        trimmedTerm,
        trimmedTranslation,
        snippetTextFormat
      );

      let dict = createSnippetDictionary(text)
      dict.plainTextTranslation = trimmedTranslation
      dict.plainTextTerm = trimmedTerm
      dict.id = editSnippetID
      dict.pinned_at = snippetPinnedAt

      setPlainTextModel(dict);
    }
  }, [plainTextTerm, plainTextTranslation, snippetTextFormat, editSnippetID]);

  useEffect(() => {
    if (!addSnippetMode) {
      onModelChanged?.(null);
      return;
    }
    switch (addSnippetMode) {
      case AddEditSnippetMode.PLAIN_TEXT:
        console.log("plain text model " + JSON.stringify(plainTextModel))
        onModelChanged?.(plainTextModel);
        break;
      case AddEditSnippetMode.FROM_DICTIONARY:
        // console.log("search model " + JSON.stringify(editedSearchModels));

        // let model = editedSearchModels;

        // if ((searchTermTranslation ?? "").trim().length === 0) {
        //   model = null;
        // }

        onModelChanged?.(dictionaryModel);
        break;
      default:
        break;
    }
  }, [addSnippetMode, dictionaryModel, plainTextModel]);

  useEffect(() => {
    setSearchTermTranslation(userDefinition?.text);
  }, [userDefinition]);

  // useEffect(() => {
  //   console.log("search model " + JSON.stringify(inputModels));
  //   if (!inputModels) {
  //     return;
  //   }

  //   let definitions = inputModels?.definitions ?? {}
  //   setDefinitions(definitions);
  //   setSelectedDefinition(definitions?.[0]);

  //   let hasData = isDefined(inputModels)
  //   // inputModel?.header?.text &&  
  //   // inputModel.header.text.length > 0;

  //   console.log('Setting has data ' + hasData)
  //   setSearchModelHasData(true);
  // }, [inputModels]);

  useEffect(() => {
    setSearchTermTranslation(selectedDefinition);
  }, [selectedDefinition]);

  // useEffect(() => {
  //   if (addSnippetMode === AddEditSnippetMode.PLAIN_TEXT) {
  //     let trimmedTerm = plainTextTerm?.trim();
  //     let trimmedTranslation = plainTextTranslation?.trim();
  //     let text = snippetTextFromFormat(
  //       trimmedTerm,
  //       trimmedTranslation,
  //       snippetTextFormat
  //     );
  //   } else if (addSnippetMode === AddEditSnippetMode.FROM_DICTIONARY) {
  //     const term = searchText?.toLowerCase().trim() ?? "";
  //   }
  // }, [
  //   searchText,
  //   searchTermTranslation,
  //   plainTextTerm,
  //   plainTextTranslation,
  //   snippetTextFormat,
  //   addSnippetMode,
  // ]);

  async function onSearch() {
    await doSearch(searchText)
  }

  async function doSearch(searchText) {
    const text = searchText?.trim();
    if (!text) {
      return;
    }
    setSearching(true);
    const startTime = Date.now();
    try {
      const searchResult = await dictionarySearch(
        text,
        learningLanguage.code,
        userLanguage.code
      );

      let snippets = await convertDictionarySearchResultToSnippetModels(searchResult, userLanguage.code, learningLanguage.code, true)

      const endTime = Date.now();
      const elapsedTime = endTime - startTime;
      logEvent_AddSnippet_SearchDictionarySuccess(
        text,
        learningLanguage.code,
        userLanguage.code,
        elapsedTime
      );
      setSearchModels(snippets);
      // setSearchModel(snippets);
    } catch (error) {
      const endTime = Date.now();
      const elapsedTime = endTime - startTime;
      logEvent_AddSnippet_SearchDictionaryError(
        error,
        text,
        learningLanguage.code,
        userLanguage.code,
        elapsedTime
      );
      toast({
        description: error.message,
        position: "top",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    } finally {
      setSearching(false);
    }
  }

  useEffect(() => {
    if (snippetStackRef.current) {
      const height = snippetStackRef.current.offsetHeight;
      setSnippetStackHeight(height);
      console.log("Snippet Box Height:", height);
    }
  }, [onModelChanged]);

  useEffect(() => {
    onLoading?.(isSearching);
  }, [isSearching]);

  function selectDefinition(index) {
    setSelectedDefinition(definitions[index])
    // setSelectedDefinition(definition);
  }

  // setSelectedSearchModelID(searchModels[index].id)
  //               setSelectedDefinitionID(selectedDefinitionID)
  //               setSelectedDefinitionCustomTranslation(definitionCustomTranslation)
  useEffect(() => {
    // selected definition changed 
    regenerateDictionaryModel()
  }, [selectedSearchModelID, selectedDefinitionID, selectedDefinitionCustomTranslation, selectedSections, userLanguage])

  useEffect(() => {
    if (addSnippetMode === AddEditSnippetMode.FROM_DICTIONARY) {
      regenerateDictionaryModel()
    } else if (addSnippetMode === AddEditSnippetMode.PLAIN_TEXT) {
      regeneratePlainTextModel()
    }
  }, [addSnippetMode])


  function regeneratePlainTextModel() {
    let model = plainTextModel ?? {}
    model.id = editSnippetID
    model.plainTextTerm = plainTextTerm
    model.plainTextTranslation = plainTextTranslation

    setPlainTextModel(plainTextModel)
  }

  function regenerateDictionaryModel() {
    console.log(`regenerate model ${selectedSearchModelID} ${selectedDefinitionID}`);
    let searchModel = null;

    if (selectedSearchModelID && searchModels) {
      let model = searchModels.find((m) => m.id === selectedSearchModelID);

      // check if custom translation is empty
      console.log('selectedDefinitionCustomTranslation ' + selectedDefinitionCustomTranslation);
      console.log('Model is ' + JSON.stringify(model));

      if (model) {
        let modelCopy = copyJSONObject(model);
        modelCopy.customDefinition = null;

        let definition = null
        if (model.definitions && model.definitions[selectedDefinitionID]) {
          definition = model.definitions[selectedDefinitionID];
        } else {
          console.log('setting search model to null');
        }
        
        let plainTextTranslation = null;
        let selectedDefinition = null;

        if (definition) {
          selectedDefinition = {
            id: selectedDefinitionID,
            languageCode: userLanguage?.code || '',
          };
        } else {
          let isCustomTranslationValid =
            isDefined(selectedDefinitionCustomTranslation) && selectedDefinitionCustomTranslation?.trim().length > 0;

          if (isCustomTranslationValid) {
            modelCopy.customDefinition = selectedDefinitionCustomTranslation;
          } else {
            console.log('aborting creating model');
            setDictionaryModel(null);
            return;
          }
        }

        modelCopy.id = editSnippetID;
        modelCopy.plainTextTranslation = plainTextTranslation;
        modelCopy.selectedDefinition = selectedDefinition;
        modelCopy.selectedSections = selectedSections || [];
        modelCopy.pinned_at = snippetPinnedAt || null;
        console.log('snippet-modal-content-create.js: modelCopy ' + JSON.stringify(modelCopy));
        searchModel = modelCopy;

      }
    }
    console.log('setting dictionary model ' + JSON.stringify(searchModel));
    setDictionaryModel(searchModel);
  }

  async function onInflectionObjectClick(inflection) {
    console.log('onClickInflectionEntry', inflection)
    if(inflection.verb) {
      setSearchText(inflection.verb)
      doSearch(inflection.verb)
    }
  }

  return (
    <Box pt={0} pb={4} data-test='main-content'>
      <RadioGroup
        data-test='radio-group'
        pl={4}
        pr={4}
        ref={snippetStackRef}
        value={addSnippetMode}
        color="white"
        onChange={(value) => {
          setAddSnippetMode(value);
          console.log("onChange " + value);
        }}
      >
        <Stack>
          <Radio value={AddEditSnippetMode.PLAIN_TEXT} data-test='radio-plain-text'>
            <Text>{t("create_snippet.as_text")}</Text>
          </Radio>
          {addSnippetMode === AddEditSnippetMode.PLAIN_TEXT && (
            <Box
              opacity={
                addSnippetMode !== AddEditSnippetMode.PLAIN_TEXT
                  ? OPACITY_NOT_SELECTED
                  : 1
              }
            >
              <Text>{t("create_snippet.term_title")}</Text>

              <Input
                data-test='input-plain-term'
                mb={2}
                onKeyDown={(e) => {
                  handleEnterKeyPress(e, onEnterKeyPress);
                }}
                flexGrow={1}
                isDisabled={
                  isSearching || addSnippetMode !== AddEditSnippetMode.PLAIN_TEXT
                }
                onChange={(e) => {
                  setPlainTextTerm(e.target.value);
                }}
                color="white"
                value={plainTextTerm}
              ></Input>

              <Text>{t("create_snippet.translation_title")}</Text>
              <Input
                data-test='input-plain-translation'
                mb={2}
                onKeyDown={(e) => {
                  handleEnterKeyPress(e, onEnterKeyPress);
                }}
                flexGrow={1}
                isDisabled={
                  isSearching || addSnippetMode !== AddEditSnippetMode.PLAIN_TEXT
                }
                onChange={(e) => {
                  setPlainTextTranslation(e.target.value);
                }}
                color="white"
                value={plainTextTranslation}
              ></Input>
            </Box>
          )}
          <Radio value={AddEditSnippetMode.FROM_DICTIONARY} data-test='radio-from-dictionary'>
            <Text color="white">{t("create_snippet.search_dictionary")}</Text>
          </Radio>

          <VStack>
            <HStack
              opacity={
                addSnippetMode !== AddEditSnippetMode.FROM_DICTIONARY
                  ? OPACITY_NOT_SELECTED
                  : 1
              }
              w="100%"
              justifyContent="space-between"
            >
              <Input
                data-test='input-search-text'
                onKeyDown={(e) => {
                  handleEnterKeyPress(e, onSearch);
                }}
                flexGrow={1}
                isDisabled={
                  isSearching ||
                  addSnippetMode !== AddEditSnippetMode.FROM_DICTIONARY
                }
                autoFocus
                color="white"
                value={searchText}
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
              ></Input>
              <Button
                data-test='button-search'
                colorScheme="blue"
                isDisabled={
                  isSearching ||
                  addSnippetMode !== AddEditSnippetMode.FROM_DICTIONARY
                }
                onClick={() => {
                  onSearch();
                }}
              >
                {t("sentences.search")}
              </Button>
            </HStack>

            {searchModels && searchModels.length > 0 &&
              // searchModelHasData &&
              addSnippetMode === AddEditSnippetMode.FROM_DICTIONARY && (
                <Flex direction={"column"} justifyItems="center">
                  {/* <Divider mt={0} /> */}
                  <Text
                    color="white"
                    fontSize="large"
                    textAlign="center"
                    style={{ fontWeight: "bold" }}
                    mt={2}
                    onChange={(e) => { }}
                  >
                    {t("common.search_results")}
                  </Text>
                </Flex>
              )}
          </VStack>
        </Stack>
      </RadioGroup>
      <Box
        pl={[0, "16px"]}
        pr={[0]}
        mr={2}
        overflowY="auto"
        maxHeight={snippetTableHeight}
        data-test='snippet-table-box'
      >
        {searchModels && addSnippetMode === AddEditSnippetMode.FROM_DICTIONARY &&
          <DictionarySearchResults
            showEditHeaders={true}
            editMode={true}
            results={searchModels}
            showsCreateButton={false}
            selectionMode={DictionarySearchSelection.SINGLE}
            onLinkClick={(obj) => {
              onInflectionObjectClick(obj);
            }}
            selectedItemID={selectedSearchModelID}
            isSnippetSelectable={ (snippet) => {
              return !snippet.inflectionEntry
            }}
            onSelectionChanged={(models, indices) => {
              console.log('sm cc ' + JSON.stringify(models) + ' ' + JSON.stringify(indices))
              if (models.length === 1 && indices.length === 1) {
                let index = indices[0]
                let model = models[0]
                let newSelectedDefinitionID = model.selectedDefinitionID
                let definitionCustomTranslation = model.customDefinitionText
                console.log(`ssseettting to ${searchModels[index].id} ${newSelectedDefinitionID} ${definitionCustomTranslation}`)
                let searchModelID = searchModels[index].id
                if (searchModelID !== selectedSearchModelID) {
                  setSelectedSearchModelID(searchModelID)
                }
                if (selectedDefinitionID !== newSelectedDefinitionID) {
                  setSelectedDefinitionID(newSelectedDefinitionID)
                }

                if (selectedDefinitionCustomTranslation !== definitionCustomTranslation) {
                  setSelectedDefinitionCustomTranslation(definitionCustomTranslation)
                }

                function compareObjects(o1, o2) {
                  if (Object.keys(o1).length !== Object.keys(o2).length) {
                    return false
                  }
                  for (let key in o1) {
                    if (o1[key] !== o2[key]) {
                      return false
                    }
                  }

                  return true
                }

                console.log('Comparing s ' + JSON.stringify(selectedSections) + ' ' + JSON.stringify(model.visibleSections))
                if (!compareObjects(selectedSections ?? {}, model.visibleSections ?? {})) {
                  console.log('ssSsssSssett')
                  setSelectedSections(model.visibleSections)
                }

              } else {
                setSelectedSearchModelID(null)
                setSelectedDefinitionID(null)
                setSelectedDefinitionCustomTranslation(null)
              }
            }}
          />
        }
      </Box>
    </Box>
  );
}

export default SnippetModalContentCreate;

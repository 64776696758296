
export const Tag = {
    "movie": { id: 'movie', name: 'Movie' },
    "dialogue": { id: 'dialogue', name: 'Dialogue' },
    "cafe": { id: 'cafe', name: 'Cafe' },
    "office": { id: 'office', name: 'Office' },
    "everyday": { id: 'everyday', name: 'Everyday' },
    "technology": { id: 'technology', name: 'Technology' },
    "startup": { id: 'startup', name: 'Startup' },
    "gaming": { id: 'gaming', name: 'Gaming' },
    "travel": { id: 'travel', name: 'Travel' },
    "sports": { id: 'sports', name: 'Sports' },
    "social": { id: 'social', name: 'Social' },
}
export function randomID() {
    // generate random id string from alphanumeric characters with length 16
    return Math.random().toString(36).substring(2, 10) + Math.random().toString(36).substring(2, 10)
}

export function random8ID() {
    // generate random id string from alphanumeric characters with length 8
    return Math.random().toString(36).substring(2, 10)
}

export const validateEmail = (email) => {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const copyJSONObject = (obj) => {
    return JSON.parse(JSON.stringify(obj))
}

export const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const valueOr = (value, defaultValue) => {
    return (value !== undefined && value !== null) ? value : defaultValue
}

export const isDefined = (value) => {
    return value !== undefined && value !== null
}

// layout

export const numberOfComponentsInLayoutString = (layoutString) => {
    // returns number of components, e.g. '112' -> 4 (1 + 1 + 2)
    if (!layoutString) return 0
    return layoutString.split('').reduce((acc, val) => acc + parseInt(val), 0)
}

export const focusRefDelayed = (ref, after = 100) => {
    console.log('focusing element ' + ref?.current + ' ' + ref + ' after ' + after + 'ms')
    focusHTMLElementDelayed(ref?.current, after)
}

export const focusHTMLElementDelayed = (element, after = 100) => {
    console.log('focusing element ' + element + ' after ' + after + 'ms')
    setTimeout(() => {
        element?.focus()
    }, after)
}
import { httpsCallable } from "@firebase/functions";
import { database, functions } from "./firebaseConfig";
import { convertDictionarySearchResultToSnippetModels, createStorySnippetTemplate, localizePerson, removeAccents } from "../utils/snippet-utils";
import { get, ref } from "@firebase/database";
import { Constants } from "../utils/constants";

// translationService.js
const GOOGLE_API_ENDPOINT = "https://translation.googleapis.com/language/translate/v2";
const GOOGLE_API_KEY = "AIzaSyB63na5-BdryiJ6eULyIPFbMAXGkcJpCIk";

export async function translateText(text, sourceLang, targetLang) {
  const url = `${GOOGLE_API_ENDPOINT}?key=${GOOGLE_API_KEY}&q=${encodeURIComponent(text)}&source=${sourceLang}&target=${targetLang}&format=text`;

  const response = await fetch(url, { method: "POST" });
  const data = await response.json();

  if (data && data.data && data.data.translations && data.data.translations.length > 0) {
    return data.data.translations[0].translatedText;
  } else {
    throw new Error("Failed to translate text");
  }
}

export async function loadStorySnippets(mapping, definitions, learningLanguageCode, userLanguageCode, t) {

  let promises = []

  console.log(`definitions are ${JSON.stringify(definitions)} in ${JSON.stringify(mapping)}`)

  let snippets = mapping.snippets ?? {}
  console.log('Snippets are ' + JSON.stringify(snippets))
  for (let snippetKey in snippets) {
    let snippet = snippets[snippetKey]
    if(snippet.template) {
      console.log("getting template")
      let getTemplateSnippetPromise = createStorySnippetTemplate(snippet.text, snippet.template, snippet.word_type, t).then((snippet) => {
        if(snippet) {
          console.log('returning snippet ' + JSON.stringify(snippet))
         return [snippet]
        } else {
          console.log('returning null')
          return null
        }
      })
      promises.push(getTemplateSnippetPromise)
    } else {
      console.log("getting snippet")
      const promiseGetOtherSnippet = loadSingleSnippetForStoryMapping(snippet, mapping.id, snippet.id, definitions, learningLanguageCode, userLanguageCode)
      promises.push(promiseGetOtherSnippet)
    }
  }

  return Promise.all(promises).then((results) => {
    // filter null results
    let filtered = results.filter(result => result)

    // each result is an array, concat them
    return filtered.flat()
  })
}

export async function loadSingleSnippetForStoryMapping(mapping, mappingKey, snippetKey, definitions, learningLanguageCode, userLanguageCode) {
  const { text: word, infinitive: infinitiveVerb } = mapping;

  console.log('Loading single snippet for ' + word)

  if (!word || !learningLanguageCode) {
    console.log('Aborting because of missing word or learningLanguageCode')
    return Promise.resolve(null);
  }

  let termToSearch = word

  if (infinitiveVerb) {
    termToSearch = infinitiveVerb
  }

  try {
    console.log('Searching dictionary for ' + termToSearch)
    let searchResult = await dictionarySearch(termToSearch, learningLanguageCode, userLanguageCode)
    if (searchResult) {
      console.log('Received search result for ' + termToSearch)
      let snippets = await convertDictionarySearchResultToSnippetModels(searchResult, userLanguageCode, learningLanguageCode, true)
      return prepareStorySnippets(snippets, mappingKey, snippetKey, mapping, definitions, userLanguageCode)
    } else {
      console.log('Search result is null')
      return null
    }
  } catch (error) {
    console.log('dictionary search error: ' + error)
    return null
  }
}

function prepareStorySnippets(snippets, mappingKey, snippetKey, mapping, definitions, userLanguageCode) {
  console.log('Snippets to prepare ' + JSON.stringify(snippets))

  let filteredSnippets = snippets.filter(snippet => snippet.wordType === mapping.word_type)

  if (filteredSnippets.length === 0) {
    console.log('Filtering failed; couldn\'t find snippet with word type ' + mapping.word_type)
    filteredSnippets = snippets
  }

  // "selectedDefinitionIDs": {
  //   "en": {
  //       "saasidja29oppp": {
  //           "saasidja29oppp": "66c70f72cg9qlN8f"
  //       }

  //   }
  // }

  // {
  //   "key": "saasidja29oppp",
  //   "text": "Era",
  //   "word_type": "verb",
  //   "infinitive": "ser",
  //   "person": 3,
  //   "tense": "imperfect",
  //   "count": "singular",
  //   "range": {
  //       "location": 0,
  //       "length": 3
  //   },
  //   "other_snippets": {
  //       "otherSnippetKey": {

  //       }
  //   },
  // },



  let selectedDefinitionID = definitions[snippetKey]

  console.log(`Trying to select definition for ${snippetKey} = ${selectedDefinitionID} in definitions ${JSON.stringify(definitions)}`)
  console.log('keys are ' + JSON.stringify(Object.keys(definitions)))
  if (selectedDefinitionID) {
    console.log('Iterating through ' + JSON.stringify(filteredSnippets))
    for (let snippet of filteredSnippets) {
      if (snippet.definitions[selectedDefinitionID]) {
        // definition exists
        console.log('FOund!')
        snippet.selectedDefinition = { id: selectedDefinitionID, languageCode: userLanguageCode }
      }
    }
  }

  return filteredSnippets
}

export async function dictionarySearch(word, learningLanguageCode, userLanguageCode) {

  // unaccented term
  let unaccentedTerm = removeAccents(word.trim().toLowerCase())

  let promiseTranslation = null

  let promises = []
  let promiseData = get(ref(
    database,
    `${Constants.DatabasePath.DICTIONARY_CACHE}/${learningLanguageCode}/${unaccentedTerm}/forms/`,
  ))
  promises.push(promiseData)

  if (userLanguageCode !== 'en') {
    promiseTranslation = get(ref(database, `${Constants.DatabasePath.DICTIONARY_CACHE_TRANSLATION}/${learningLanguageCode}/${userLanguageCode}/${unaccentedTerm}`))
    promises.push(promiseTranslation)
  }

  let results = await Promise.all(promises)

  let snapshot = results[0]
  let data = snapshot?.val()
  let result = null
  if (snapshot?.exists() && data) {
    result = {}

    if (userLanguageCode !== 'en') {
      let translation = results[1]?.val()
      if (!translation) {
        console.log('Missing translation')
        let translateHttpsCallable = httpsCallable(functions, 'snippets-fetch_missing_snippet_translation');

        translation = await translateHttpsCallable({
          term: word,
          learningLanguageCode: learningLanguageCode,
          userLanguageCode: userLanguageCode
        })

        translation = translation?.data ?? translation
        console.log('Translation returned is ' + JSON.stringify(translation))
      }

      if (translation) {
        for (let word in translation) {
          let wordTypesObject = translation[word]
          for (let wordType in wordTypesObject) {
            let translationObject = wordTypesObject[wordType]
            if (data[word]?.[wordType] && translationObject.definitions) {
              data[word][wordType].definitions = translationObject.definitions
            }
          }

        }
        console.log('Added translation ' + JSON.stringify(data))
      }
    }

    if (data[word]) {
      result[word] = data[word]
    } else {
      result = data
    }
  } else {
    const generateSnippets = httpsCallable(functions, 'snippets-search_snippets_term');
    result = await generateSnippets({
      text: word.trim().toLowerCase(),
      snippetLanguage: learningLanguageCode,
      userLanguage: userLanguageCode,
    })

    let model = result?.data?.result ?? result?.data
    console.log('acc data: ' + JSON.stringify(model))
    if (!model) {
      // todo localize
      throw new Error("No results found")
    } else {
      console.log('Model returned from search_snippets_term: ' + JSON.stringify(model))
      result = model
    }
  }

  if (result) {
    for (let word in result) {
      let dict = result[word]
      for (let wordType in dict) {
        let wordDict = dict[wordType]
        if (wordType !== 'inflections') {
          wordDict.definitionsLanguageCode = userLanguageCode
        }
      }
    }
  }

  return result
}
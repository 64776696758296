import { BrowserRouter, Route, Routes, redirect } from "react-router-dom";
import "./App.css";
import React, { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { analytics, auth } from "./api/firebaseConfig";
import { lazy, Suspense, useCallback } from "react";
import {
  checkUserLanguagesAndRedirect,
  getUserLanguageCodeFromLocalStorage,
  updateLanguages,
} from "./api/user-language.service";
import { UserProvider } from "./auth/user-context";
import { useTranslation } from "react-i18next";
import { getLanguageWithCode } from "./utils/languages";
import { RecoilRoot, useRecoilState } from "recoil";
import {
  isAuthenticationFinishedState,
  learningLanguageState,
  userLanguageState,
  userState,
} from "./state/user-state";

import { classroomIDState } from "./state/classroom-state";
import {
  hasAnyLocalData,
  hasLocalClassrooms,
  hasLocalSentences,
  hasLocalSnippets,
  printLocalData,
} from "./api/localStorage";
import { mergeDataState } from "./state/app-state";
import { logEvent_LoginDialog_LoginSuccess } from "./analytics/analytics";
import { setUserProperties } from "firebase/analytics";
import { getDatabase, ref, get } from "firebase/database";
import Story from "./stories/story";
import AddStory from "./stories/add-story";
import StoryList from "./stories/story-list";

const Documents = lazy(() => import("./documents/documents"));
const Terms = lazy(() => import("./terms/terms"));
const PrivacyPolicy = lazy(() => import("./privacy-policy/privacy-policy"));
const Sentences = lazy(() => import("./components/sentences"));
const Register = lazy(() => import("./components/register"));
const ChooseLanguage = lazy(() => import("./components/chooseLanguage"));
const UserProfile = lazy(() => import("./components/profile"));
const ClassroomList = lazy(
  () => import("./components/classrooms/classroom-list"),
);
const Classroom = lazy(() => import("./components/classrooms/classroom"));
const AddSnippetModal = lazy(() => import("./dialogs/add-snippet-modal"));
const UpdateMissingDataModal = lazy(
  () => import("./dialogs/update-missing-data-modal"),
);
const MergeDataModal = lazy(() => import("./dialogs/merge-data-modal"));
const MemoizedDocuments = React.memo(Documents);
const MemoizedTerms = React.memo(Terms);
const MemoizedPrivacyPolicy = React.memo(PrivacyPolicy);
const MemoizedSentences = React.memo(Sentences);
const MemoizedStory = React.memo(Story);
const MemoizedAddStory = React.memo(AddStory);
const MemoizedStoriesList = React.memo(StoryList);
const MemoizedRegister = React.memo(Register);
const MemoizedChooseLanguage = React.memo(ChooseLanguage);
const MemoizedUserProfile = React.memo(UserProfile);
const MemoizedClassroomList = React.memo(ClassroomList);
const MemoizedClassroom = React.memo(Classroom);
const MemoizedAddSnippetModal = React.memo(AddSnippetModal);
const MemoizedUpdateMissingDataModal = React.memo(UpdateMissingDataModal);
const MemoizedMergeDataModal = React.memo(MergeDataModal);

function App() {
  const [classroomID, setClassroomID] = useRecoilState(classroomIDState);
  const [learningLanguage, setLearningLanguage] = useRecoilState(
    learningLanguageState,
  );
  const [userLanguage, setUserLanguage] = useRecoilState(userLanguageState);
  const [mergeState, setMergeState] = useRecoilState(mergeDataState);
  const [user, setUser] = useRecoilState(userState);
  const [initiallyLoggedIn, setInitiallyLoggedIn] = useState(null);
  const [authenticationFinished, setAuthenticationFinished] = useRecoilState(
    isAuthenticationFinishedState,
  );

  const { i18n } = useTranslation();

  useEffect(() => {
    printLocalData();

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      let snapshot;
      let userData;

      if (user) {
        const db = getDatabase();
        const userRef = ref(db, "users/" + user?.uid);
        snapshot = await get(userRef);
        userData = snapshot.exists() ? snapshot.val() : {};
      }
      setUser({
        uid: user?.uid,
        email: user?.email,
        displayName: user?.displayName,
        photoURL: user?.photoURL,
        providerId: user?.providerId,
        dateOfBirth: userData?.dateOfBirth,
      });

      setUserProperties(analytics, {
        userId: user?.uid,
        email: user?.email,
        provider: user?.providerId,
      });

      if (authenticationFinished && !initiallyLoggedIn && user) {
        return;
      }

      if (user) {
        console.log("user is logged in");
        // check if user logged in with google

        checkUserLanguagesAndRedirect(user.uid).then((languages) => {
          if (languages) {
            console.log("check callback: userLanguage", languages.userLanguage);
            console.log(
              "check callback: learningLanguage",
              languages.learningLanguage,
            );
            setUserLanguage(getLanguageWithCode(languages.userLanguage));
            setLearningLanguage(
              getLanguageWithCode(languages.learningLanguage),
            );
          } else {
            setUserLanguageFromLocalStorage();
          }

          if (initiallyLoggedIn == null) {
            setInitiallyLoggedIn(true);
          }

          setAuthenticationFinished(true);
        });
      } else {
        console.log("user is null");
        // user is not logged in
        if (initiallyLoggedIn == null) {
          setInitiallyLoggedIn(false);
        }
        setUser(null);
        setUserLanguageFromLocalStorage();
        setAuthenticationFinished(true);
      }
    });
    return () => unsubscribe();
  }, [authenticationFinished]);

  useEffect(() => {
    if (user && authenticationFinished && initiallyLoggedIn === false) {
      handleLoginSuccess(user);
    }
  }, [user, initiallyLoggedIn, authenticationFinished]);

  const handleLoginSuccess = useCallback(
    (user) => {
      logEvent_LoginDialog_LoginSuccess();
      if (hasAnyLocalData()) {
        const data = {
          hasLocalSentences: hasLocalSentences(),
          hasLocalSnippets: hasLocalSnippets(),
          hasLocalClassrooms: hasLocalClassrooms(),
          isOpen: true,
        };
        setMergeState(data);
      } else {
        console.log("redirecting");
        window.location.href = "/documents";
      }
    },
    [setMergeState],
  );

  const setUserLanguageFromLocalStorage = useCallback(() => {
    let localUserLanguageCode = getUserLanguageCodeFromLocalStorage();
    if (!localUserLanguageCode) {
      localUserLanguageCode = "en";
    }
    let userLanguage = getLanguageWithCode(localUserLanguageCode);
    setUserLanguage(userLanguage);
    console.log("setUserLanguageFromLocalStorage " + userLanguage);
  }, [setUserLanguage]);

  async function getServerUserLanguage() {
    const db = getDatabase();
    const userRef = ref(db, "users/" + user?.uid);
    const snapshot = await get(userRef);
    const userData = snapshot.exists() ? snapshot.val() : {};
    return userData?.userLanguage;
  }

  async function getServerLearningLanguage() {
    const db = getDatabase();
    const userRef = ref(db, "users/" + user?.uid);
    const snapshot = await get(userRef);
    const userData = snapshot.exists() ? snapshot.val() : {};
    return userData?.learningLanguage;
  }

  useEffect(() => {
    if (userLanguage) {
      i18n.changeLanguage(userLanguage.code);
    }
    if (user) {
      getServerUserLanguage().then((data) => {
        if (data) {
          updateLanguages(user?.uid, userLanguage, null);
        }
      });
    }
  }, [userLanguage, i18n, user?.uid]);

  useEffect(() => {
    if (learningLanguage) {
      if (user) {
        getServerLearningLanguage().then((data) => {
          if (data) {
            updateLanguages(user?.uid, null, learningLanguage);
          }
        });
      }
    }
  }, [learningLanguage, user?.uid]);

  return (
    <div className="app" style={{ height: "100vh", width: "100%" }}>
      <UserProvider
        value={{
          user,
          userLanguage,
          setUserLanguage,
          authenticationFinished,
          isInitiallyLoggedIn: initiallyLoggedIn,
        }}
      >
        <BrowserRouter>
          <Suspense fallback={<div></div>}>
            <Routes>
              <Route path="/documents" element={<MemoizedDocuments />} />
              <Route path="/terms" element={<MemoizedTerms />} />
              <Route
                path="/privacy-policy"
                element={<MemoizedPrivacyPolicy />}
              />
              <Route
                path="/document/:language/:id"
                element={<MemoizedSentences />}
              />
              <Route path="/register" element={<MemoizedRegister />} />
              <Route
                path="/choose-language"
                element={<MemoizedChooseLanguage />}
              />
              <Route path="/profile" element={<MemoizedUserProfile />} />
              <Route path="/classrooms" element={<MemoizedClassroomList />} />
              <Route path="/classroom/:id" element={<MemoizedClassroom />} />
              <Route path='/story/:language/:id' element={<MemoizedStory />} />
              <Route path="/add-story" element={<MemoizedAddStory />} />
              <Route path="/stories" element={<MemoizedStoriesList />} />
              <Route path="/" element={<MemoizedSentences />} />
              <Route path="*" element={<MemoizedSentences />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
        <Suspense fallback={<div></div>}>
          <MemoizedAddSnippetModal />
          <MemoizedUpdateMissingDataModal />
          <MemoizedMergeDataModal />
        </Suspense>
      </UserProvider>
    </div>
  );
}

export default App;

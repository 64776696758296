import { Box, Button, Center, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, Grid, GridItem, Image, Spinner, Text, useDisclosure, useToast, VStack } from "@chakra-ui/react";
import Header from "../components/header";
import SEO from "../seo/seo";
import Footer from "../components/footer";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { userLanguageState, userState } from "../state/user-state";
import { useEffect, useRef, useState } from "react";
import ComboBox from "../components/combobox";
import { Tag } from "./tags";
import { searchStories } from "../api/story.service";
import { useNavigate } from "react-router-dom";
import { isPageValidState } from "../state/app-state";

const numberOfColumns = 5;
const verticalSpacingHeaderContent = 8
const verticalSpacingContentFooter = 4;

function StoryList() {
    const [userLanguage, setUserLanguage] = useRecoilState(userLanguageState);
    const [isSearching, setIsSearching] = useState(false);
    const [storiesListBoxHeight, setStoriesListBoxHeight] = useState("0px");
    const [user, setUser] = useRecoilState(userState);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [stories, setStories] = useState(null);
    const [isPageValid, setIsPageValid] = useRecoilState(isPageValidState);

    const [filterLevels, setFilterLevels] = useState([
        { id: "beginner", title: "Beginner" },
        { id: "intermediate", title: "Intermediate" },
        { id: "advanced", title: "Advanced" },
    ]);

    const [filterLanguages, setFilterLanguages] = useState([
        { id: "es", title: "Spanish", imageName: "flag-es.png" },
    ]);

    const [filterCategories, setFilterCategories] = useState([
        Object.keys(Tag).map((key) => {
            return { id: Tag[key].id, title: Tag[key].name, imageName: `tag-${key}.png` }
        })
    ]);

    const [selectedLevel, setSelectedLevel] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [selectedCategories, setSelectedCategories] = useState([]);

    // Filters local state
    const [filtersLocalSelectedLevel, setFiltersLocalSelectedLevel] = useState(null);
    const [filtersLocalSelectedLanguage, setFiltersLocalSelectedLanguage] = useState(null);
    const [filtersLocalSelectedCategories, setFiltersLocalSelectedCategories] = useState([]);

    const headerRef = useRef(null);

    const navigate = useNavigate();

    const { t } = useTranslation();
    const toast = useToast();

    useEffect(() => {
        setIsPageValid(true);

        reloadStoriesHeight()
    }, []);

    useEffect(() => {
        if(!userLanguage) {
            return
        }

        if(stories == null) {
            let language = filterLanguages?.[0]
            setSelectedLanguage(language);
            // setSelectedFilters([language])
        }
        
        // let stories = [];
        // for (let i = 0; i < 25; i++) {
        //     stories.push({
        //         "id": "lotr-story-id",
        //         "created_at": 1724510802000,
        //         "difficulty": "intermediate",
        //         "level": "B1",
        //         "title": "El Señor de los Anillos",
        //         "title_translations": {
        //             "de": "Der Herr der Ringe",
        //             "en": "The Lord of the Rings",
        //             "fr": "Le Seigneur des Anneaux",
        //             "it": "Il Signore degli Anelli",
        //             "nl": "In de Ban van de Ring",
        //             "pt": "O Senhor dos Anéis",
        //             "ru": "Властелин колец",
        //             "se": "Härskarringen",
        //             "sr": "Господар прстенова"
        //         }
        //     });
        // }
        // setStories(stories);

      
    }, [userLanguage]);

    useEffect(() => {
        if(!userLanguage || !selectedLanguage) {
            return
        }
        
        let filters = []
        if(selectedLanguage) {
            filters.push(selectedLanguage)
        }

        if(selectedLevel) {
            filters.push(selectedLevel)
        }

        if(selectedCategories) {
            filters.push(...selectedCategories)
        }

        setSelectedFilters(filters)

        search()

    }, [selectedLevel, selectedLanguage, selectedCategories, userLanguage]);

    useEffect(() => {
        reloadStoriesHeight()
    }, [user, headerRef?.current])

    function reloadStoriesHeight() {
        // Calculate and set the height of the scrollable box
        if (headerRef.current) {
            const headerHeight = headerRef.current.getBoundingClientRect().height;
            const viewportHeight = window.innerHeight;
            const calculatedHeight = viewportHeight - headerHeight - 128 - verticalSpacingHeaderContent - verticalSpacingContentFooter // 128px is the height of the header
            setStoriesListBoxHeight(`${calculatedHeight}px`);
        }
    }

    const onLanguageChange = (language) => {
        setFiltersLocalSelectedLanguage(language);
    };

    const onLevelChange = (level) => {
        setFiltersLocalSelectedLevel(level);
    };

    const onClickApply = () => {
        setSelectedLanguage(filtersLocalSelectedLanguage);
        setSelectedLevel(filtersLocalSelectedLevel);
        setSelectedCategories(filtersLocalSelectedCategories);
        onClose();
        // setSelectedFilters(getFiltersFromLocalState())
    };

    const onSelectStory = (index) => {
        let story = stories[index];
        let languageCode = story.languageCode;
        let storyId = story.id;

        navigate(`/story/${languageCode}/${storyId}`, { replace: true });
    };

    function getSelectedFilters() {
        let filters = [];
        if (selectedLanguage) {
            filters.push(selectedLanguage);
        }

        if (selectedLevel) {
            filters.push(selectedLevel);
        }

        return filters;
    }

    function getFiltersFromLocalState() {
        let filters = [];
        if (selectedLanguage) {
            filters.push(selectedLanguage);
        }

        if (selectedLevel) {
            filters.push(selectedLevel);
        }

        return filters;
    }

    const search = async () => {
        try {
            setIsSearching(true);
            let difficulties = [];
            if(selectedLevel) {
                difficulties.push(selectedLevel.id);
            }
            let stories = await searchStories(null, userLanguage.code, selectedLanguage.id, difficulties, null, null, 'alpabetical', 0, 10);
            setStories(stories);
        } catch (error) {
            console.error(error);
            toast({
                title: error.message, //t('stories.error_searching'),
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            setStories([]);
        } finally {
            setIsSearching(false);
        }
    };

    const onClickFilter = () => {
        setFiltersLocalSelectedLevel(selectedLevel);
        setFiltersLocalSelectedLanguage(selectedLanguage);
        setFiltersLocalSelectedCategories(selectedCategories);
        onOpen()
    }

    return (
        <>
            <Header />
            <Box
                as="main"
                h="calc(100vh - 128px)"
                data-test="stories-page"
                style={{
                    backgroundImage: "url('/icons/bg.png')",
                    backgroundSize: "cover",
                    backgroundRepeat: "repeat-y",
                    backgroundPosition: "center center",
                }}
            >
                <SEO pageKey={"stories"} />

                <Flex ref={headerRef} data-key='header-data-panen' direction='column' px={8} pt={4}>
                    {/* displayName */}
                    {user?.displayName && <Text fontSize="lg" color='gray.300'>
                        {t("stories.greeting", { name: user.displayName })}
                    </Text>
                    }
                    <Text fontSize="3xl" fontWeight="bold" color="white">
                        {t("stories.stories")}
                    </Text>
                    <Box data-test='recommended-panel'>

                    </Box>
                    <Box data-test='filters'>

                    </Box>
                    <Box data-test='stories'>
                        <Flex direction='row' h='44px' spacing={4} alignItems='center' mt={2}>
                            <Button _hover={{ backgroundColor: "purple", color: "white" }} leftIcon={<Image src="/icons/filter-stories.png" boxSize="20px" color='white' />} backgroundColor='lightPurple' borderWidth={1} borderColor='gray.600' color="white" size="lg" data-test='filters-button' onClick={onClickFilter}>
                                {t("stories.filters")}
                            </Button>
                            {
                                selectedFilters.map((filter) => {
                                    return <Flex _hover={{ backgroundColor: 'purple' }} userSelect="none" cursor="pointer" ml={2} px={2} py={1} h='100%' backgroundColor='filterPurple' borderRadius='lg' alignItems='center'>
                                        {filter.imageName && <Image h='100%' src={`/icons/${filter.imageName}`} />}
                                        <Text ml={filter.imageName ? 1 : 0} key={filter} fontWeight='medium' color="gray.300">{filter.title}</Text>
                                    </Flex>
                                })
                            }
                        </Flex>
                    </Box>
                </Flex>
                <Box
                    data-test="stories-list-box"
                    mt={`${verticalSpacingHeaderContent}px`}
                    mx={8}
                    h={storiesListBoxHeight}  // Dynamic height
                    overflowY="auto"  // Enable vertical scrolling if content overflows
                >
                    { isSearching && <Center><Spinner speed='0.5s' color="white" size="xl" /> </Center> }
                    <Grid
                        templateColumns={`repeat(${numberOfColumns}, 1fr)`}
                        gap={6}
                        h="100%"
                    >   
                        {!isSearching && stories && stories.map((story, index) => (
                            <GridItem key={story.id} w="100%">
                                <Box
                                    onClick={() => onSelectStory(index)}
                                    _hover={{ backgroundColor: "darkPurple" }}
                                    userSelect="none"
                                    cursor="pointer"
                                    borderWidth={1}
                                    borderRadius="lg"
                                    borderColor="gray.600"
                                    p={4}
                                    mt={4}
                                    data-test="story-card"
                                >
                                    <Flex direction="row">
                                        <FallbackImage
                                            py={1}
                                            px={1}
                                            src={story.imageURL ?? ""}
                                            fallbackSrc="/icons/story-placeholder-blue.png"
                                            boxSize="100px"
                                        />
                                        <Box ml={4}>
                                            <Text fontSize="lg" fontWeight="bold" color="white">
                                                {story.title}
                                            </Text>
                                            <Text fontSize="md" color="gray.300">
                                                {story.level}
                                            </Text>
                                        </Box>
                                    </Flex>
                                </Box>
                            </GridItem>
                        ))}
                    </Grid>
                </Box>

                <Drawer
                    isOpen={isOpen}
                    placement='right'
                    onClose={onClose}
                >
                    <DrawerOverlay />
                    <DrawerContent backgroundColor='darkPurple'>
                        <DrawerCloseButton color='white' />
                        <DrawerHeader color='white'>{t('stories.filters')}</DrawerHeader>

                        <DrawerBody>
                            <VStack spacing={1} alignItems='start'>
                                <Text color='gray.300'>{t('stories.filters_level')}</Text>
                                <ComboBox
                                    minWidth={'50px'}
                                    items={filterLevels}
                                    preSelectedItem={filtersLocalSelectedLevel}
                                    onItemSelect={onLevelChange}
                                />
                                <Text color='gray.300' mt={4}>{t('stories.filters_language')}</Text>
                                <ComboBox
                                    disableEmpty={true}
                                    items={filterLanguages}
                                    preSelectedItem={filtersLocalSelectedLanguage}
                                    onItemSelect={onLanguageChange}
                                />
                                {/* <Text color='gray.300' mt={4}>{t('stories.filters_categories')}</Text> */}
                            </VStack>
                        </DrawerBody>

                        <DrawerFooter>
                            <Button color='white' variant='outline' mr={3} onClick={onClose}>
                                {t('modal.cancel')}
                            </Button>
                            <Button colorScheme='blue' onClick={onClickApply}>{t('stories.filters_apply')}</Button>
                        </DrawerFooter>
                    </DrawerContent>
                </Drawer>
            </Box >
            <Footer />
        </>
    );
}

function FallbackImage({ src, fallbackSrc, ...props }) {
    const [imgSrc, setImgSrc] = useState(src);

    return (
        <Image
            src={imgSrc}
            onError={() => setImgSrc(fallbackSrc)} // Fallback to placeholder if image fails to load
            {...props}
        />
    );
}

export default StoryList;

import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { learningLanguageState, userState } from "../state/user-state";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUser } from "../auth/user-context";
import { Box, Center, Divider, Spinner, Text, useToast } from "@chakra-ui/react";
import SEO from "../seo/seo";
import Header from "../components/header";
import Footer from "../components/footer";
import StoryDetails from "./story-details";
import SnippetTable from "../snippets/snippet-table";
import { dictionarySearch, loadStorySnippets } from "../api/translation.service";
import { convertDictionarySearchResultToSnippetModels } from "../utils/snippet-utils";
import DictionarySearchResults from "../components/dictionary-search-result";
import { selectedStoryMappingState } from "../state/story-state";
import { Constants } from "../utils/constants";
import { getStory } from "../api/story.service";
import { isPageValidState } from "../state/app-state";
import { getLanguageWithCode, LANGUAGES } from "../utils/languages";

function Story() {
    // check sentence modal
    // state
    const [story, setStory] = useState(null);
    const [resultSnippets, setResultSnippets] = useState(null);
    const [isLoadingSnippet, setIsLoadingSnippet] = useState(false);
    const [storyName, setStoryName] = useState(null);
    const [isPageValid, setIsPageValid] = useRecoilState(isPageValidState);
    const [selectedMapping, setSelectedMapping] = useRecoilState(selectedStoryMappingState)
    const [learningLanguage, setLearningLanguage] = useRecoilState(
        learningLanguageState
    );

    const { language, id } = useParams();
    const navigate = useNavigate();
    const toast = useToast();
    const { t } = useTranslation();
    const [user, setUser] = useRecoilState(userState);

    const {
        userLanguage,
        setUserLanguage,
        authenticationFinished,
        isInitiallyLoggedIn,
    } = useUser();

    useEffect(() => {
        if (!learningLanguage || story || !id || !language) {
            return
        }

        // check if language is valid
        if (!getLanguageWithCode(language)) {
            // invalid language
            navigate('/stories')
            return;
        }

        setSelectedMapping(null)
        console.log('Calling getStory', id, language)
        // fetch(`/mock_data/story-example-lotr-new-format.json`)
        //     .then((response) => response.json())
        //     .then((story) => {
        getStory(id, language).then((story) => {

            if (!story) {
                toast({
                    title: t('story.story_not_found'),
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                // story not found
                navigate('/stories')
                return;
            }

            setIsPageValid(true)
            console.log('Fetched Story ', JSON.stringify(story, null, 2))
            for (let sentenceKey in story.sentences) {
                let sentence = story.sentences[sentenceKey];
                if (sentence.mappings) {
                    sentence.mappings = sentence.mappings.sort((a, b) => a.range.location - b.range.location);
                }
            }

            if (typeof story.sentences === 'object') {
                story.sentences = Object.values(story.sentences);
            }

            setStory(story);

            setStoryName(`${story.title} ${learningLanguage.name} ${t('story.story')} - ${Constants.PAGE_TITLE_APP_NAME}`);
        }).catch((error) => {
            console.log('cant fetch story', error)
        })

    }, [id, language, learningLanguage, story]);

    function fixModel(model) {
        let m = fixRanges(model);

        return m
    }

    function fixMissingMappings(model) {
        return model
    }

    function fixRanges(model) {
        model.sentences.forEach(sentence => {
            const sentenceText = sentence.text;
            const correctedMappings = {};

            let currentPosition = 0;

            // Iterate through each mapping
            for (let key in sentence.mappings) {
                const mapping = sentence.mappings[key];
                const wordText = mapping.text;

                // Find the correct position of the word in the sentence text
                const newLocation = sentenceText.indexOf(wordText, currentPosition);
                const newLength = wordText.length;

                if (newLocation !== -1) {
                    // Update the mapping's range
                    mapping.range.location = newLocation;
                    mapping.range.length = newLength;

                    // Move the current position pointer
                    currentPosition = newLocation + newLength;
                } else {
                    console.warn(`Word "${wordText}" not found in sentence: "${sentenceText}"`);
                }

                // Save the corrected mapping
                correctedMappings[key] = mapping;
            }

            // Replace the old mappings with corrected ones
            sentence.mappings = correctedMappings;
        });

        return model;
    }

    const onSelectMapping = async (mapping) => {
        setSelectedMapping({ mapping: mapping, story: story })
        setResultSnippets(null)

        if (!mapping) {
            return
        }
        console.log('onSelectMapping', mapping)

        let learningLanguageCode = learningLanguage?.code
        // let wordType = mapping.word_type
        // let word = mapping.text

        if (!learningLanguageCode) {
            console.log('return')
            return
        }

        try {
            setIsLoadingSnippet(true)

            let definitions = story.selected_definitions?.[userLanguage.code] ?? {}
            let snippets = await loadStorySnippets(mapping, definitions, learningLanguageCode, userLanguage.code, t)

            setResultSnippets(snippets)
        } catch (error) {
            console.error(error)
            setResultSnippets(null)
        } finally {
            setIsLoadingSnippet(false)
        }
    }

    return (
        <>
            <Header />
            <Box
                data-test="story-page"
                h="calc(100vh - 128px)"
                flexDirection="column"
                style={{
                    backgroundImage: "url('/icons/bg.png')",
                    backgroundSize: "cover",
                    backgroundRepeat: "repeat-y",
                    backgroundPosition: "center center",
                }}
            >
                <SEO pageKey="story" title={storyName} />

                {story && <Box data-test='main-box' h='100%' py={2}>
                    <Box display="flex" height="100%" >
                        <Box flex="7" px="4" h='100%'>
                            {/* Content for the 70% width panel */}
                            <StoryDetails story={story} onSelectMapping={onSelectMapping} />
                        </Box>
                        <Divider h='100%' opacity={0.2} orientation="vertical" />
                        <Box flex="3" h='100%'>
                            {selectedMapping?.mapping?.text && <Box
                                h='36px'
                                direction='column'
                                alignContent='center'
                                borderRadius='md'
                                alignItems='center'
                                backgroundColor='rgba(255, 255, 255, 0.1)'
                                mx={2}
                                px={2}
                            >
                                <Center>
                                    <Text fontSize='xl' fontWeight='bold' color='white'>{selectedMapping.mapping.text}</Text>
                                    {isLoadingSnippet && <Box ml={2}>
                                            <Spinner color='gray' />
                                        
                                    </Box>}
                                </Center>
                            </Box>}
                            {/* Content for the 30% width panel */}
                            <Box overflowY="auto" h='calc(100% - 36px)'>
                                {resultSnippets && <DictionarySearchResults highlightedText={selectedMapping?.mapping?.text?.toLowerCase()} results={resultSnippets} classroomID={null} showsCreateButton={false} />}
                            </Box>



                        </Box>
                    </Box>
                </Box>
                }
            </Box>
            <Footer />
        </>
    );
}

export default Story;


import { useEffect, useState } from "react";
import SnippetModal from "../snippets/snippet-modal";
import { useRecoilState } from "recoil";
import { learningLanguageState, userLanguageState, userState } from "../state/user-state";
import { addSnippet, editSnippet, loadSnippets } from "../api/sentences.service";
import { showSnippetsModalState, localUserSnippetsState, snippetsNeedUpdateState, snippetsState } from "../state/snippets-state";
import { useTranslation } from "react-i18next";
import { addEditClassroomSnippet } from "../api/classroom.service";
import { reload } from "firebase/auth";
import { randomID } from "../utils/utils";

export const AddSnippetModalType = {
    CREATE_NEW: 'CREATE_NEW', // shows when user clicks on plus button in snippets header
    FROM_DICTIONARY: 'FROM_DICTIONARY', // shows when user clicks on a 'Create Snippet' in dictionary search
    FROM_TRANSLATION: 'FROM_TRANSLATION', // shows when user clicks on a 'Create Snippet' after performing a translation
    EDIT_EXISTING: 'EDIT_EXISTING' // shows when user clicks on a 'Edit Snippet' in the snippet table
}

// loads data from global modelState, initializes isShowing and passes it to the SnippetModal component
// and handles createSnippet call by passing the model to the uploadSnippet function;
// model is the data from the SnippetModal component
function AddSnippetModal() {
    const [localSnippets, setLocalSnippets] = useRecoilState(localUserSnippetsState)
    const [modalState, setShowingSnippetsModal] = useRecoilState(showSnippetsModalState)
    const [snippetsLoadID, setSnippetsLoadID] = useRecoilState(snippetsNeedUpdateState)
    const [isShowing, setIsShowing] = useState(false)
    const [modalType, setModalType] = useState(AddSnippetModalType.CREATE_NEW)
    const [isSubmitting, setSubmitting] = useState(false)
    const [user, setUser] = useRecoilState(userState);
    const [learningLanguage, setLearningLanguage] = useRecoilState(learningLanguageState);
    const [userLanguage, setUserLanguage] = useRecoilState(userLanguageState);

    const { t } = useTranslation()

    useEffect(() => {
        setIsShowing(modalState?.isShowing)
        setModalType(modalState?.type ?? AddSnippetModalType.CREATE_NEW)
        console.log('AddSnippetModal modalState changed: ' + JSON.stringify(modalState))
    }, [modalState])

    async function handleCreateSnippet(model) {
        if (!model) {
            return
        }
        try {
            setSubmitting(true)
            await uploadSnippet(modalState?.editSnippetID, modalState?.classroomID, model)

            // hide snippet modal, set isShowing to false
            let state = { ...modalState }
            state.isShowing = false
            console.log('updating state' + JSON.stringify(state))
            setShowingSnippetsModal(state)
        } catch (error) {
            console.error('Error uploading snippet:', error)
        } finally {
            setSubmitting(false)
            if(!user) {
                await reloadLocalSnippets()
            } else {
                const id = randomID()
                setSnippetsLoadID(id)
            }
            
        }
        // setSnippetModels(prev => [uploadedSnippet, ...prev])
    }

    async function reloadLocalSnippets() {
        if (!user && learningLanguage?.code) {
            let snippets = await loadSnippets(null, learningLanguage.code)
            console.log('LOCALL SNIPPETS: ' + JSON.stringify(snippets))
            setLocalSnippets(snippets)
        }
    }

    const uploadSnippet = async (snippetID, classroomID, snippetDictionary) => {
        // console.log(`uploadSnippet: ${snippetID}, ${classroomID}, ${JSON.stringify(snippetDictionary)} user: ${JSON.stringify(user ?? {})} lang: ${learningLanguage?.code}`)
        // if (classroomID) {
        //     await addEditClassroomSnippet(classroomID, snippetDictionary)
        // } else {
        //     if (snippetID) {
        //         console.log(`editing snippet ${snippetID} ${classroomID} ${JSON.stringify(snippetDictionary)} user: ${JSON.stringify(user ?? {})} lang: ${learningLanguage?.code}`)
        //         await editSnippet(snippetID, classroomID, snippetDictionary, user?.uid, learningLanguage.code)
        //     } else {
        //         console.log(`adding snippet ${JSON.stringify(snippetDictionary)} user: ${JSON.stringify(user ?? {})} lang: ${learningLanguage?.code}`)
        //         return addSnippet(snippetDictionary, user?.uid, learningLanguage.code)
        //     }
        // }
    }

    const onCloseSnippetsModal = () => {
        let state = { ...modalState }
        state.isShowing = false
        setShowingSnippetsModal(state)
    }

    return <SnippetModal
        customTitle={modalState?.title}
        isOpen={isShowing}
        type={modalType}
        onClose={onCloseSnippetsModal}
        cellModel={modalState?.models}
        languageCode={learningLanguage?.code}
        userLanguageCode={userLanguage?.code}
        snippetKey={modalState?.editSnippetID}
        loading={isSubmitting}
        onCreate={(model, hiddenSections) => handleCreateSnippet(model, hiddenSections)}
    />

}

export default AddSnippetModal;
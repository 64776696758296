// languages.js

export const LANGUAGES = [{ code: "en", name: "English", localizedName: "English" }].concat([
    { code: "es", name: "Spanish", localizedName: "Español"},
    { code: "sr", name: "Serbian", localizedName: "Српски" },
    { code: "ru", name: "Russian", localizedName: "Русский" },
    { code: "it", name: "Italian", localizedName: "Italiano" },
    { code: "hr", name: "Croatian", localizedName: "Hrvatski" },
    // { code: "fr", name: "French", localizedName: "Français" },
    // { code: "de", name: "German", localizedName: "Deutsch"},
    // { code: "pt", name: "Portuguese", localizedName: "Português" },
    // { code: "nl", name: "Dutch", localizedName: "Nederlands" },
    // { code: "el", name: "Greek", localizedName: "Ελληνικά" },
    // { code: "sv", name: "Swedish", localizedName: "Svenska" },
    // { code: "tr", name: "Turkish", localizedName: "Türkçe" },
  ].sort((a, b) => a.name.localeCompare(b.name) ))

const supportedSnippetLanguageCodes = ['es']

export function isLanguageDictionarySupported(languageCode) {
    return supportedSnippetLanguageCodes.includes(languageCode)
}
  
export function getLanguageWithCode(code) {
    if(!code) {
        return null
    }
    return LANGUAGES.find((language) => language.code === code);
}

export function getLanguageWithName(name) {
    if(!name) {
        return null
    }
    return LANGUAGES.find((language) => language.name.toLowerCase() === name.toLowerCase());
}

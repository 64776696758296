import { serverTimestamp } from "@firebase/database";
import { convertWiktionaryFetchResultToSnippetsArray, createInflectionSnippet, createSnippet, mergeSnippetAndWiktionaryResultIntoSnippet } from "./snippet-wiktionary-parsing";
import { copyJSONObject, isDefined, randomID } from "./utils";
import { SharedConstants } from "../shared/shared";

export function getVisibleSectionsFromSnippet(snippet) {
  function findSectionsObject(s) {
    if (!isDefined(s)) {
      return null
    }
    if (typeof s == 'object') {
      if (s.sections) {
        return s.sections
      } else {
        for (let key in s) {
          let sectionsObj = findSectionsObject(s[key])
          if (sectionsObj) {
            return sectionsObj
          }
        }
      }
    }
    return null
  }

  let visibleSections = {}
  let sections = findSectionsObject(snippet)
  if (sections) {
    for (let sectionObj of sections) {
      if (sectionObj.id && !sectionObj.hidden) {
        visibleSections[sectionObj.id] = true
      }
    }
  }

  return visibleSections
}

export function isTextHighlighted(text, selectedText) {
  if (!text || !selectedText) {
    return false;
  }

  const regex = accentInsensitiveRegex(selectedText);
  return regex.test(text);
}

export function escapeRegex(string) {
  return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
}

export function accentInsensitiveRegex(input) {
  const accentMap = {
    a: '[aáàãäâ]',
    e: '[eéèëê]',
    i: '[iíìïî]',
    o: '[oóòöõô]',
    u: '[uúùüû]',
    c: '[cç]',
    n: '[nñ]',
  };

  let regexString = escapeRegex(input).toLowerCase();

  regexString = regexString.split('').map((char) => accentMap[char.toLowerCase()] || char).join('');

  return new RegExp(`(${regexString})`, 'gi'); // Capture groups to include the matched substring in the split array
}

export function removeAccents(string) {
  const accentMap = {
    á: 'a', à: 'a', ã: 'a', ä: 'a', â: 'a',
    é: 'e', è: 'e', ë: 'e', ê: 'e',
    í: 'i', ì: 'i', ï: 'i', î: 'i',
    ó: 'o', ò: 'o', ö: 'o', õ: 'o', ô: 'o',
    ú: 'u', ù: 'u', ü: 'u', û: 'u',
    ç: 'c',
    ñ: 'n'
  };

  return string.split('').map(char => accentMap[char] || char).join('');
}

export function createSnippetDictionary(text, items) {
  let snippetModel = {
    "header": {
      "id": randomID(),
      "type": "text",
      "text": text
    },
    items: (items ?? [])
  }
  return snippetModel
}

export function findSnippetTextValues(snippet, result = null) {

  if (!snippet) {
    return {}
  }

  let rootLevel = false

  if (!result) {
    result = {}
    rootLevel = true
  }

  if (snippet.type === 'text' && snippet.text !== undefined) {

    let escapedText = removeAccents(snippet.text).toLowerCase().trim()
    if (escapedText.length > 0) {
      result[escapedText] = true
    }

  }

  for (let obj of snippet.items ?? []) {
    // Check if the current object meets the condition
    if (obj.type === 'text' && obj.text !== undefined) {

      let escapedText = removeAccents(obj.text).toLowerCase().trim()
      if (escapedText.length > 0) {
        result[escapedText] = true
      }
    }
  }

  // Iterate through each property of the object
  for (const key in snippet) {
    if (typeof snippet[key] === 'object') {
      // Recursively search in nested objects
      findSnippetTextValues(snippet[key], result);
    } else if (Array.isArray(snippet[key])) {
      for (let item of snippet[key]) {
        if (typeof item === 'object') {
          findSnippetTextValues(item, result)
        }
      }
    }
  }

  if (rootLevel) {
    let toReturn = Object.keys(result)
    return toReturn
  } else {
    return result
  }
}

export function findSnippetHeaderValues(obj) {

  if (!obj) {
    return []
  }

  let result = []
  let all = {}
  let keys = ["plainTextTerm", "plainTextTranslation", "customDefinition", "term"]

  for (let key of keys) {
    if (obj[key]) {
      let escapedText = removeAccents(obj[key]).toLowerCase().trim()
      if (escapedText.length > 0) {
        all[escapedText] = true
      }
    }
  }

  if (obj.selectedDefinition?.id && obj.definitions?.[obj.selectedDefinition.id]) {
    // find definition text
    let definition = obj.definitions?.[obj.selectedDefinition.id]
    if (definition) {
      let text = definition.raw ?? definition.text
      if (text) {
        let escapedText = removeAccents(text).toLowerCase().trim()
        if (escapedText.length > 0) {
          all[escapedText] = true
        }
      }
    }
  }

  for (let key in all) {
    result.push(key)
  }

  console.log(`findSnippetHeaderValues ${result} for ${JSON.stringify(obj)}`)

  return result

}

export function localizeSnippetModels(snippetModels, t) {
  return snippetModels
  if (!snippetModels) {
    return []
  }

  for (let model of snippetModels) {
    localizePerson(model, t)
  }

  return snippetModels
}

export function prepareSnippets(arr, t) {
  if (!arr) {
    return
  }

  let snippets = []

  for (let snippet of arr) {
    let obj = { ...snippet }

    if (!obj) {
      continue
    }
    if (!obj.filterTextItems) {
      obj.filterTextItems = findSnippetTextValues(snippet)
    }

    if (!obj.filterHeaderItems) {
      obj.filterHeaderItems = findSnippetHeaderValues(snippet)
    }


    snippets.push(obj)
  }

  return localizeSnippetModels(snippets, t)
}

export function localizePerson(model, t) {
  if (!model || typeof model !== 'object') {
    return
  }

  if (model.type === 'person' && model.index) {
    let prefix = 'st'
    switch (model.index) {
      case 1:
        prefix = 'st'
        break
      case 2:
        prefix = 'nd'
        break
      case 3:
        prefix = 'rd'
        break
      default:
        prefix = 'th'
        break
    }

    if (t) {
      model.text = t(`lang.${model.index}${prefix}-person ${model.count}`)
    }
  }

  for (let key in model) {
    if (typeof model[key] === 'object') {
      localizePerson(model[key])
    }
  }
}

export function mergeSnippetWithTransltion(snippet, translation) {
  console.log('mergeSnippetWithTransltion', snippet, translation);
  if (translation?.definitions) {
    snippet.definitions = translation.definitions;
  }
  return snippet;
}

export async function convertDictionarySearchResultToSnippetModels(searchResult, userLanguageCode, learningLanguageCode, autoselectFirstDefinition = false) {
  console.log('convertDictionarySearchResultToSnippetModels', searchResult);

  let snippets = []
  console.log("Search Result " + JSON.stringify(searchResult))
  for (let accentedWord in searchResult) {
    console.log(`wordKey ${accentedWord} in object ${JSON.stringify(searchResult[accentedWord])}`)
    for (let wordType in searchResult[accentedWord]) {
      if (wordType === 'inflections') {
        let inflectionsDictionary = searchResult[accentedWord][wordType]

        for (let inflectionKey in inflectionsDictionary) {
          let inflection = inflectionsDictionary[inflectionKey]
          console.log(`parsing inflection ${inflectionKey} ${JSON.stringify(inflection)} in dictionary ${JSON.stringify(inflectionsDictionary)}`)
          let obj = await createInflectionSnippet(randomID(), inflection)
          snippets.push(obj)
        }
      } else {
        let dictionaryEntry = searchResult[accentedWord][wordType]
        console.log(`DIctionary entry ${JSON.stringify(dictionaryEntry)}`)
        let definitions = dictionaryEntry.definitions
        console.log('Definitions ' + JSON.stringify(definitions))

        let definitionKey = null
        if (definitions && Object.keys(definitions).length > 1) {
          definitionKey = Object.keys(definitions)[0]
        }
        console.log('entry ' + JSON.stringify(dictionaryEntry))

        let obj = createSnippet(randomID(), wordType, dictionaryEntry.definitions, null, accentedWord, dictionaryEntry.conjugation, null, definitionKey, Date.now(), null, learningLanguageCode, autoselectFirstDefinition)
        obj.dictionaryEntry = copyJSONObject(dictionaryEntry)

        // FIXME: Fix to use different keys for different languages
        if (learningLanguageCode === 'es') {
          let tenseKeys = Object.keys(SharedConstants.Dictionary.Tense.Spanish)
          let selectedSections = {}
          for (let tenseKey of tenseKeys) {
            selectedSections[tenseKey] = true
          }
          obj.selectedSections = selectedSections
        }

        if (dictionaryEntry.definitions && Object.keys(dictionaryEntry.definitions).length > 1) {
          obj.definitionsLanguageCode = userLanguageCode
        }
        console.log('Created snippet ' + JSON.stringify(obj))
        snippets.push(obj)
      }
    }
  }

  return snippets
}

export function convertDictionaryEntryToSnippetModel(id, dictionaryEntry, userSnippet, languageCode) {

  if (!dictionaryEntry) {
    console.log('convertDictionaryEntryToSnippetModel: No dictionary entry for ' + id)
    return []
  }
  return mergeSnippetAndWiktionaryResultIntoSnippet(id, userSnippet, dictionaryEntry, languageCode).then((snippets) => {
    console.log('convertDictionaryEntryToSnippetModel', JSON.stringify(snippets));

    return snippets
  })
}

export function generateUserSnippetsObject(appSnippet, languageCode) {
  console.log('language code is ' + languageCode)
  console.log('app snippet ' + JSON.stringify(appSnippet))
  let obj = {}
  if (appSnippet.userSnippet) {
    obj = copyJSONObject(appSnippet.userSnippet)
  }

  if (!obj.createdAt) {
    obj.createdAt = serverTimestamp()
  }

  obj.id = obj.id ?? (appSnippet.id ?? appSnippet.key)

  obj.selectedDefinition = appSnippet.selectedDefinition
  obj.customDefinition = appSnippet.customDefinition
  obj.lastUpdatedAt = serverTimestamp()
  obj.plainTextTerm = appSnippet.plainTextTerm
  obj.plainTextTranslation = appSnippet.plainTextTranslation
  obj.selectedSections = appSnippet.selectedSections
  obj.pinned_at = appSnippet.pinned_at
  obj.definitionsLanguageCode = appSnippet.definitionsLanguageCode

  if (!obj.link && appSnippet.wordType && appSnippet.term) {
    // update link object
    let link = {}
    link.wordType = appSnippet.wordType.toLowerCase()
    link.accentedTerm = appSnippet.term
    link.term = removeAccents(appSnippet.term ?? '').toLowerCase()
    link.language = languageCode
    obj.link = link
  }

  return obj
}

function createSnippetHeaderWithMultipleDefinitions(term, definitions) {
  console.log('createSnippetHeader ' + definitions[0])
  let firstDefinitionDictionary = definitions[0]?.text
  let firstDefinition = firstDefinitionDictionary?.text ?? firstDefinitionDictionary?.raw

  let parsedDefinitions = []
  for (let definition of definitions) {
    let textDictionary = definition.text
    if (!textDictionary) {
      continue
    }
    if (textDictionary.text) {
      parsedDefinitions.push(textDictionary.text)
    } else if (textDictionary.raw) {
      parsedDefinitions.push(textDictionary.raw)
    }
  }
  console.log('edited definitions ' + parsedDefinitions)

  return {
    id: randomID(),
    type: "text",
    term,
    translation: firstDefinition,
    definitions: parsedDefinitions,
    collapsable: false,
    collapsesSnippet: true
  }
}

// FIXME: Snippet dict hardcoded for now
export async function createStorySnippetTemplate(term, templateDictionary, wordType, t) {

  let templateName = templateDictionary.type

  let localizationKey = 'story_snippet_templates.' + templateName

  let templateDictionaryCopy = copyJSONObject(templateDictionary)
  delete templateDictionaryCopy.type

  let translation = t(localizationKey, templateDictionaryCopy)
  
  if(!translation || translation === localizationKey) {
    console.log('Aborting template creation for ' + JSON.stringify(templateDictionary))
    console.log('Translation is ' + translation)
    return null
  } else {
    console.log('Creating template for ' + templateName + ' ' + translation)
  }

  // const person = i18next.t(`inflection.person.${inflection.person}`);
  //   const number = i18next.t(`inflection.number.${inflection.number}`);
  //   const tense = i18next.t(`inflection.tense.${inflection.tense}`);
  //   const verb = inflection.verb;
  //   return i18next.t('inflection.template', { person, number, tense, verb });

  let id = randomID()
  return {
    id: id,
    key: id,
    wordType, 
    pinned_at: null, 
    plainTextTerm: term, 
    plainTextTranslation: translation
  }
}
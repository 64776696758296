import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { AddSnippetModalType } from "../dialogs/add-snippet-modal";
import SnippetModalContentCreate from "./snippet-modal-content-create";
import { hideAddEditSnippetModal, setUserSnippetsNeedUpdate, showSnippetsModalState, snippetsNeedUpdateState } from "../state/snippets-state";
import { generateUserSnippetsObject } from "../utils/snippet-utils";
import { learningLanguageState, userState } from "../state/user-state";
import { addEditSnippet, prepareSnippetForSaving } from "../api/sentences.service";
import { classroomIDState } from "../state/classroom-state";
import { addEditClassroomSnippet } from "../api/classroom.service";
export const AddEditSnippetMode = {
  PLAIN_TEXT: "PLAIN_TEXT",
  FROM_DICTIONARY: "FROM_DICTIONARY",
};
function SnippetModal({
  isOpen,
  onClose,
  loading,
  cellModel,
  onCreate,
  type,
  languageCode,
  userLanguageCode,
  snippetKey,
  customTitle,
}) {
  const [editedModel, setEditedModel] = useState(cellModel);
  const [modalDialogState, setModalDialogState] = useRecoilState(
    showSnippetsModalState
  );
  const [editModel, setEditModel] = useState(null);
  
  const [user, setUser] = useRecoilState(userState)

  const [classroomID, setClassroomID] = useState(null)

  const [learningLanguage, setLearningLanguage] = useRecoilState(learningLanguageState)
  const [editingSnippetID, setEditingSnippetID] = useState(snippetKey);
  const [isLoading, setLoading] = useState(loading);

  const [userSnippetsNeedUpdate, userSnippetsUpdateSetter] = useRecoilState(snippetsNeedUpdateState)
  const [modalTitleHeight, setModalTitleHeight] = useState(0);
  
  const [modalFooterHeight, setModalFooterHeight] = useState(0);
  const [snippetModels, setSnippetModels] = useState(modalDialogState?.models);

  const [addSnippetMode, setAddSnippetMode] = useState(
    AddEditSnippetMode.FROM_DICTIONARY
  );
  const modalTitleRef = useRef(null);
  const modalFooterRef = useRef(null);

  const { t } = useTranslation();

  const toast = useToast();

  const [maxModalHeight, setMaxModalHeight] = useState("auto");

  useEffect(() => {
    const externalFooter = document.getElementById("external-footer"); // Referencing the footer
    const externalFooterHeight = externalFooter
      ? externalFooter.offsetHeight
      : 0;

    setMaxModalHeight(`calc(100vh - 32px)`);
  }, [loading, cellModel]);

  useEffect(() => {
    console.log('Model state changed ' + JSON.stringify(modalDialogState));

    let editModel = {
      editSnippetID: modalDialogState?.editSnippetID,
      pinned_at: modalDialogState?.pinned_at,
      models: modalDialogState?.models,
      searchText: modalDialogState?.searchTerm,
      plainTextTerm: modalDialogState?.plainTextTerm,
      plainTextTranslation: modalDialogState?.plainTextTranslation,
      selectedModelID: modalDialogState?.selectedModelID,
      definitionsLanguageCode: modalDialogState?.definitionsLanguageCode,
    };
    
    if(editModel?.plainTextTerm || editModel?.plainTextTranslation) {
      setAddSnippetMode(AddEditSnippetMode.PLAIN_TEXT);
    } else {
      setAddSnippetMode(AddEditSnippetMode.FROM_DICTIONARY);
    }

    setClassroomID(modalDialogState?.classroomID)

    setEditModel(editModel);

    console.log('Setting edit model to ' + JSON.stringify(editModel));
    // setSnippetModels(modalDialogState?.models);
  }, [modalDialogState]);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  useEffect(() => {
    setEditingSnippetID(snippetKey);
  }, [snippetKey]);
  
  useEffect(() => {
    if (modalFooterRef.current) {
      const height = modalFooterRef.current.offsetHeight;
      setModalFooterHeight(height);
    }
    if (modalTitleRef.current) {
      const height = modalTitleRef.current.offsetHeight;
      setModalTitleHeight(height);
    }

    console.log('Snippet model changed ' + JSON.stringify(snippetModels));
  }, [snippetModels]);

  async function onClickCreate() {
    console.log("onClickCreate " + JSON.stringify(editedModel));

    let saveObject = generateUserSnippetsObject(editedModel, languageCode)
    console.log(`Save object(for id ${saveObject.id}) is ${JSON.stringify(saveObject)}`);
    
    try {
      setLoading(true)
      if(classroomID) {
        let snippetID = saveObject.id
        let preparedSnippet = prepareSnippetForSaving(saveObject)
        await addEditClassroomSnippet(classroomID, preparedSnippet, snippetID)
      } else {
        await addEditSnippet(saveObject.id, saveObject, user?.uid, learningLanguage.code)
      }
      
      setUserSnippetsNeedUpdate(userSnippetsUpdateSetter)
      hideAddEditSnippetModal(setModalDialogState)
    } catch (error) {
      console.log('Error saving snippet ' + error);
      toast({
        title: t("sentences.error_saving_snippet"),
        status: "error",
        duration: 3000,
      })
    } finally {
      setLoading(false);
    }
  }

  function createModelDidChange(model) {
    console.log("Create model did change " + JSON.stringify(model));
    setEditedModel(model);
  }
  
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      blockScrollOnMount={false}
      data-test='snippet-modal'
    >
      <ModalOverlay />
      <ModalContent
        margin="16px"
        bgImage="url('/icons/snippet-modal-background.png')"
        bgSize="cover"
        bgRepeat="no-repeat"
        bgPosition="center"
        overflowY="auto"
        height={
          snippetModels?.items?.length > 0 &&
          addSnippetMode === AddEditSnippetMode.FROM_DICTIONARY
            ? "calc(100vh - 32px)"
            : null
        }
        maxHeight={maxModalHeight}
        data-test='snippet-modal-content'
      >
        <ModalHeader color="white" ref={modalTitleRef} data-test='snippet-modal-header'>
          {customTitle ?? t("sentences.snippet_preview")}
        </ModalHeader>
        <ModalCloseButton color="closeGrey" data-test='modal-close-button' />
        <ModalBody padding={0} overflow="hidden" data-test='modal-body'>
          <Box pt={0} pb={4} overflowY="auto">
            <SnippetModalContentCreate
              maxModalHeight={maxModalHeight}
              modalFooterHeight={modalFooterHeight}
              modalTitleHeight={modalTitleHeight}
              editModel={editModel}
              // editSearchTerm={modalDialogState?.editSearchTerm}
              // editTerm={modalDialogState?.editTerm}
              // searchModels={snippetModels}
              setSearchModel={setSnippetModels}
              editTranslation={modalDialogState?.editTranslation}
              // editModel={modalDialogState?.model}
              onModelChanged={(model) => {
                createModelDidChange(model);
              }}
              onEnterKeyPress={onClickCreate}
              addSnippetMode={addSnippetMode}
              setAddSnippetMode={setAddSnippetMode}
            />
          </Box>
        </ModalBody>
        <ModalFooter ref={modalFooterRef} data-test='snippet-modal-footer'>
          <Button disabled={isLoading} variant="solid" mr={4} onClick={onClose} data-test='cancel-button'>
            {t("modal.cancel")}
          </Button>
          <Button
            data-test='create-button'
            isLoading={isLoading}
            isDisabled={!editedModel}
            colorScheme="blue"
            onClick={() => {
              onClickCreate();
            }}
          >
            {editingSnippetID ? t("modal.edit") : t("modal.create")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default SnippetModal;

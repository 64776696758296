import { Box, Button, Center, Checkbox, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import SnippetTable from "../snippets/snippet-table";
import { useRecoilState } from "recoil";
import { learningLanguageState, userLanguageState } from "../state/user-state";
import { useTranslation } from "react-i18next";
import { classroomIDState, classroomRoleState } from "../state/classroom-state";
import { AddSnippetModalType } from "../dialogs/add-snippet-modal";
import { ClassroomRoleEnum } from "./classrooms/classroom-role";
import { showAddEditSnippetModal, showSnippetsModalState } from "../state/snippets-state";
import { set } from "@firebase/database";
import { copyJSONObject, isDefined } from "../utils/utils";
import { convertDefinitionsDictionaryToArray } from "../utils/snippet-wiktionary-parsing";
import { getVisibleSectionsFromSnippet } from "../utils/snippet-utils";

export const DictionarySearchSelection = {
  SINGLE: 'SINGLE',
  MULTIPLE: 'MULTIPLE'
}

function DictionarySearchResultItem({ index, selected, selectionMode, onSelectionChanged, isSelectable, showCreateSnippet, onCreate, component }) {

  const [isSelected, setIsSelected] = useState(selected);

  const { t } = useTranslation();

  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  return (
    <Flex w='100%' h='100%' borderWidth={isSelected ? 2 : 0} borderRadius={8} borderColor='whiteAlpha.700' p={1} >
      {selectionMode && (!isDefined(isSelectable) || isSelectable === true) && <Center>
        <Checkbox isChecked={isSelected} onChange={(event) => { onSelectionChanged?.(index, event.target.checked, selectionMode) }}></Checkbox>
      </Center>
      }
      <Box w='100%'>{component}</Box>
      {showCreateSnippet && <Center>
        <Button pl={0} pr={0} colorScheme="green" onClick={() => { onCreate?.() }}>
          {t("sentences.create_snippet")}
        </Button>
      </Center>
      }
    </Flex>
  );
}

function DictionarySearchResults({ results, classroomID, editMode, selectionMode, isSnippetSelectable, showEditHeaders, selectedItemID, showsCreateButton, highlightedText, hideCollapseSnippet, onSelectionChanged, onLinkClick }) {
  const [learningLanguage, setLearningLanguage] = useRecoilState(learningLanguageState)
  const [userLanguage, setUserLanguage] = useState(userLanguageState);
  const [selectedIndices, setSelectedIndices] = useState(new Map());
  const [headerEditModels, setHeaderEditModels] = useState([]);
  const [classroomRole, setClassroomRole] = useRecoilState(classroomRoleState);

  const [isShowingSnippetsModal, setShowingSnippetsModal] = useRecoilState(
    showSnippetsModalState
  );

  function createSnippetFromSearchTerm(snippetModel) {
    if (!snippetModel) {
      return;
    }

    let modelCopy = JSON.parse(JSON.stringify(snippetModel, null, 2));

    if (!snippetModel.id) {
      console.log("Can't create a snippet, missing an ID.")
      return;
    }

    // if snippet has no items, remove collapsable so the chevron is not shown
    if ((modelCopy.items?.length ?? 0) === 0) {
      delete modelCopy?.header?.collapsable;
      delete modelCopy?.header?.collapsesSnippet;
    }

    // let modalModel = {
    //   isShowing: true,
    //   model: modelCopy,
    //   type: AddSnippetModalType.FROM_DICTIONARY,
    // };

    let id = null;
    if (classroomID && classroomRole === ClassroomRoleEnum.TEACHER) {
      id = classroomID;
    }

    showAddEditSnippetModal(
      setShowingSnippetsModal,
      null,
      modelCopy?.pinned_at,
      null,
      id,
      results,
      snippetModel.id,
      null,
      null,
      modelCopy?.term, // this should be the search term, not the term from the selected snippet
      modelCopy?.definitionsLanguageCode,
      AddSnippetModalType.FROM_DICTIONARY
    );
  }

  useEffect(() => {
    console.log('header edit models changed')
    let headerEditModels = []

    if (showEditHeaders && results && Array.isArray(results)) {
      for (let i = 0; i < results.length; i++) {
        headerEditModels.push(headerEditModelFor(results[i]))
      }
    }

    setHeaderEditModels(headerEditModels);
  }, [results, showEditHeaders]);

  useEffect(() => {
    const selectedIndicesMap = new Map();

    if (selectedItemID) {
      results.forEach((result, index) => {
        if (result.id === selectedItemID) {
          selectedIndicesMap.set(index, true);
        }
      });
    }

    setSelectedIndices((prev) => {
      return selectedIndicesMap
      // if (prev.size !== selectedIndicesMap.size) {
      //   return selectedIndicesMap;
      // }
      // return prev;
    });
  }, [results, selectedItemID]);


  // useEffect(() => {
  //   console.log('results updated', JSON.stringify(results));
  //   console.log('selectedItemID', selectedItemID);
  //   let selectedIndices = {}
  //   if (selectedItemID) {
  //     for (let i = 0; i < results.length; i++) {
  //       console.log('Comparing ', results[i].id, selectedItemID)
  //       if (results[i].id === selectedItemID) {
  //         console.log('Found selected item', i)
  //         selectedIndices[i] = true;
  //         break;
  //       }
  //     }
  //   }

  //   console.log('selectedIndices after onEffect', selectedIndices)
  //   setSelectedIndices(prev => {
  //     return selectedIndices
  //   });
  // }, [results, selectedItemID]);

  function notifyIndicesChanged(indicesMap) {
    const indices = Array.from(indicesMap.keys());
    const models = indices.map(index => headerEditModels[index]);
    console.log(`Calling onSelectionChanged with models: ${models} and indices: ${indices}`);
    onSelectionChanged?.(models, indices);
  }

  useEffect(() => {
    if (selectedIndices.size > 0) {
      notifyIndicesChanged(selectedIndices);
    }
  }, [selectedIndices]);

  // useEffect(() => {
  //   if (selectedIndices == null) {
  //     return;
  //   }
  //   console.log('selectedIndices changed', selectedIndices)
  //   notifyIndicesChanged(selectedIndices)
  // }, [selectedIndices]);

  function headerEditModelFor(model) {
    // "selectedDefinition":{"customTranslation":"def tr","id":"668d2f59cWqrhvDa","languageCode":"en"} 

    let definitions = null

    if(model.definitions) {
      definitions = convertDefinitionsDictionaryToArray(copyJSONObject(model.definitions))
    }

    let headerModel = {
      term: model.term ?? model.plainTextTerm,
      definitions: definitions,
      selectedDefinitionID: model.selectedDefinition?.id,
      customDefinitionText: model.customDefinition,
      collapsesSnippet: model.collapsesSnippet,
      visibleSections: getVisibleSectionsFromSnippet(model)
    }

    return headerModel
  }

  return (
    <Box id='searchResultsBox' w='100%' h='100%' data-test='search-results' >
      {results && Array.isArray(results) && <Box>

        {selectedIndices !== null && results.length > 0 && results.map((result, index) => (
          <DictionarySearchResultItem
            data-test={`search-result-item-${index}`}
            snippetModel={result}
            component={<SnippetTable
              data-test={`search-result-snippet-${index}`}
              key={index}
              editMode={editMode}
              w='100%'
              h='100%'
              id={`searchTerm_${index}`}
              showSelectedTextOnlyOnExactMatch={true}
              includeNonHighlightedSections={true}
              selectedText={highlightedText}
              editHeaderModel={headerEditModels[index]}
              hideCollapseSnippet={isDefined(hideCollapseSnippet) ? hideCollapseSnippet : true}
              savedVisibleSections={result.selectedSections}
              showMoreButton={false}
              languageCode={learningLanguage?.code}
              userLanguageCode={userLanguage?.code}
              minCellHeight={0}
              cellModel={result}
              isInitiallyExpanded={!showsCreateButton}
              isClassroom={classroomID !== null}
              onEditHeaderChanged={(editModel) => {
                notifyIndicesChanged(selectedIndices);
              }}
              onLinkClick={onLinkClick}
              // onModelChanged={(model) => {
              //   console.log('onModelChanged callback ' + JSON.stringify(model) )
              //   let vs = getVisibleSectionsFromSnippet(model)
              //   if(headerEditModels && headerEditModels[index]) {
              //     headerEditModels[index].visibleSections = vs
              //   }
              // }}
              onEditSectionsChanged={(sections) => {
                // let vs = getVisibleSectionsFromSnippet(model)
                if(headerEditModels && headerEditModels[index]) {
                  headerEditModels[index].visibleSections = JSON.parse(JSON.stringify(sections ?? {}))
                }
                notifyIndicesChanged(selectedIndices);
              }}
            />}
            selectionMode={selectionMode}
            isSelectable={ !isDefined(isSnippetSelectable) || isSnippetSelectable(result) }
            showCreateSnippet={showsCreateButton && !result.inflectionEntry}
            index={index}
            selected={selectedIndices.get(index) === true}
            onSelectionChanged={(index, selected, selectionMode) => {
              if (!selectionMode) {
                return;
              }

              setSelectedIndices(prev => {
                // Create a new Map from the previous state
                const newSelectedIndices = new Map(prev);

                if (selectionMode === DictionarySearchSelection.SINGLE) {
                  // Clear all previous selections if in SINGLE selection mode
                  newSelectedIndices.clear();
                  if (selected) {
                    newSelectedIndices.set(index, true);
                  }
                } else {
                  // For MULTIPLE selection mode, toggle the selected state
                  if (selected) {
                    newSelectedIndices.set(index, true);
                  } else {
                    newSelectedIndices.delete(index);
                  }
                }

                console.log('selection changed', index, selected, selectionMode);
                return newSelectedIndices; // Return the new state
              });
            }}
            onCreate={() => {
              createSnippetFromSearchTerm(result);
            }}
          />
        ))}
      </Box>}
    </Box>
  );
}

export default DictionarySearchResults;

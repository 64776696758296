import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { learningLanguageState, userState } from "../state/user-state";
import { useTranslation } from "react-i18next";
import { useUser } from "../auth/user-context";
import { Box, Button, Center, Divider, Spinner, Textarea, useToast } from "@chakra-ui/react";
import Header from "../components/header";
import Footer from "../components/footer";
import StoryDetails from "./story-details";
import { loadStorySnippets } from "../api/translation.service";
import DictionarySearchResults from "../components/dictionary-search-result";
import { selectedStoryMappingState } from "../state/story-state";
import { copyJSONObject, random8ID, randomID } from "../utils/utils";
import CopyToClipboardButton from "../components/copy-button";
import ChooseLanguageComponent from "../components/choose-language-component";
import { LANGUAGES } from "../utils/languages";
import { uploadStory } from "../api/story.service";

function AddStory() {
    // check sentence modal
    // state
    const [groupMode, setGroupMode] = useState(false);
    const [selectedGroupMappings, setSelectedGroupMappings] = useState([]);

    const [isUploadingStory, setIsUploadingStory] = useState(false);

    const [isFixingModel, setIsFixingModel] = useState(false);
    const [fixedModel, setFixedModel] = useState(null); // this is a fixed model with mappings array
    const [fixedModelCopyData, setFixedModelCopyData] = useState(null); // this is a fixed model with mappings dictionary that we'll use to copy to clipboard and save to the database
    const [resultSnippets, setResultSnippets] = useState(null);
    const [isLoadingSnippet, setIsLoadingSnippet] = useState(false);
    const [storyName, setStoryName] = useState(null);
    const [selectedMapping, setSelectedMapping] = useRecoilState(selectedStoryMappingState)
    const [learningLanguage, setLearningLanguage] = useRecoilState(
        learningLanguageState
    );

    const [storyLanguage, setStoryLanguage] = useState(learningLanguage);

    const [fixedModelText, setFixedModelText] = useState(''); // value of the fixed model text area

    const [newStoryText, setNewStoryText] = useState('');
    const toast = useToast();
    const { t } = useTranslation();
    const [user, setUser] = useRecoilState(userState);
    const {
        userLanguage,
        setUserLanguage,
        authenticationFinished,
        isInitiallyLoggedIn,
    } = useUser();

    useEffect(() => {
        setStoryLanguage(learningLanguage)
    }, [learningLanguage])

    async function fixModel(model) {
        let m = fixRanges(model);
        m = await fixMissingMappings(m);

        // let fixed = fixOtherSNippets(m);
        // let copy = copyJSONObject(fixed)

        // for (let sentenceKey in copy.sentences) {
        //     let sentence = copy.sentences[sentenceKey];
        //     sentence.id = random8ID()
        //     delete sentence.key

        //     for (let mapping of sentence.mappings) {
        //         delete mapping.key
        //         mapping.id = random8ID()

        //         for (let snippet of mapping.snippets) {
        //             snippet.id = random8ID()
        //             delete snippet.key
        //         }
        //     }

        // }

        console.log('converted model: ' + JSON.stringify(m, null, 2))

        // convert mapping dictionaries back to arrays
        for (let sentenceKey in m.sentences) {
            let sentence = m.sentences[sentenceKey];
            if (sentence.mappings && typeof sentence.mappings === 'object') {
                sentence.mappings = Object.values(sentence.mappings)
            }
        }

        return m
    }

    function fixOtherSNippets(model) {
        let mappingIgnoreKeys = ['other_snippets', 'range', 'key']
        let mappingPersistKeys = ['text', 'range']
        for (let sentenceKey in model.sentences) {
            let sentence = model.sentences[sentenceKey];

            if (sentence.mappings) {
                for (let mappingKey in sentence.mappings) {
                    let mapping = sentence.mappings[mappingKey];
                    let snippets = Object.values(copyJSONObject(mapping.other_snippets ?? {}))
                    let word = mapping.text
                    let wordType = mapping.word_type

                    if (word && wordType) {
                        let mappingCopy = {}
                        for (let mappingKey in mapping) {
                            if (!mappingIgnoreKeys.includes(mappingKey)) {
                                mappingCopy[mappingKey] = mapping[mappingKey]
                            }
                            if (!mappingPersistKeys.includes(mappingKey)) {
                                delete mapping[mappingKey]
                            }
                        }

                        snippets.push(mappingCopy)
                    }

                    mapping.snippets = snippets
                }
            }
        }

        return model
    }

    async function fixMissingMappings(model) {
        // we store all 'word~wordType' keys in the dictionary so we don't fetch the same word(and word type) multiple times
        let mappingDict = {}
        let promises = []

        // for each sentence
        for (let sentenceKey in model.sentences) {
            let sentence = model.sentences[sentenceKey];
            if (sentence.mappings) {
                // for each mapping
                for (let mappingKey in sentence.mappings) {
                    let mapping = sentence.mappings[mappingKey];

                    for (let snippetKey in mapping.snippets) {
                        let snippet = mapping.snippets[snippetKey]

                        if (!snippet.text || !snippet.word_type) {
                            continue
                        }

                        let compositeKey = `${snippet.text}~${snippet.word_type}`
                        let arr = mappingDict[compositeKey] ?? []
                        arr.push({ mapping, snippet })
                        mappingDict[compositeKey] = arr
                    }


                }
            }
        }

        console.log('mappingDict: ' + JSON.stringify(mappingDict, null, 2))

        // now we have distinct words in mappingDict - we fetch them now 
        for (let compositeKey in mappingDict) {
            let entries = mappingDict[compositeKey]
            if (entries.length > 0) {
                // all snippets in the array have the same word / wordType, so we can extract it from any of them

                let firstEntry = entries[0]
                let firstMapping = firstEntry.mapping
                let wordType = firstEntry.snippet.word_type

                console.log('First mapping is ' + JSON.stringify(firstMapping, null, 2))

                let promise = fetchMissingWordsIfNeeded(firstMapping, {}).then((words) => {
                    if (words && words.length > 0) {
                        entries.forEach((entry) => {
                            for (let baseWord of words) {
                                let mapping = entry.mapping
                                let snippets = mapping.snippets ?? {}

                                // check if it already contains the base word with same word type
                                let found = false
                                for (let otherSnippetKey in snippets) {
                                    let otherSnippet = snippets[otherSnippetKey]
                                    if (otherSnippet.word_type === wordType && otherSnippet.text === baseWord) {
                                        found = true
                                        break
                                    }
                                }

                                if (!found) {
                                    let newSnippet = {
                                        id: random8ID(),
                                        text: baseWord,
                                        word_type: wordType
                                    }
                                    snippets.push(newSnippet)
                                }
                            }
                        })
                    }
                })
                promises.push(promise)
            }
        }

        console.log('mappingDict: ' + JSON.stringify(mappingDict, null, 2))

        await Promise.all(promises)

        return model
    }

    function fetchMissingWordsIfNeeded(mapping, definitions) {
        return loadStorySnippets(mapping, definitions, learningLanguage.code, userLanguage.code, t).then((snippets) => {
            let words = null
            if (snippets && snippets.length > 0) {
                words = []
                for (let snippet of snippets) {
                    // find first definition from the definitions dictionary where .index == 0
                    if (snippet.definitions) {
                        let firstDefinition = Object.values(snippet.definitions).find((definition) => definition.index === 0)
                        if (firstDefinition) {
                            let defText = firstDefinition.text

                            function extractBaseWord(str) {
                                console.log('extractBaseWord: ' + str);

                                // Define the regular expression pattern with an optional dot at the end
                                const regex = /(?:singular|plural|misspelling) of (\S+)\.?/i;

                                // Execute the regex on the input string
                                const match = str.match(regex);

                                // If a match is found, return the cleaned-up captured group (base word)
                                if (match && match[1]) {
                                    // Remove any trailing non-alphabetic characters (except accents) from the base word
                                    return match[1].replace(/[^\p{L}]+$/u, '');
                                }

                                // If no match is found, return null or an appropriate value
                                return null;
                            }

                            // check if we need to fetch the singular form of the word
                            let baseWord = extractBaseWord(defText)

                            if (baseWord) {
                                words.push(baseWord)
                                console.log('Need to fetch base word: ' + baseWord)
                            } else {
                                console.log('No need to fetch base word')
                            }
                        }
                    }
                }

                if (words.length === 0) {
                    return null
                } else {
                    return words
                }
            }

            return null
        })
    }

    function fixRanges(model) {
        model.sentences.forEach(sentence => {
            const sentenceText = sentence.text;
            const correctedMappings = {};

            let currentPosition = 0;

            // Iterate through each mapping
            for (let key in sentence.mappings) {
                const mapping = sentence.mappings[key];
                const wordText = mapping.text;

                // Find the correct position of the word in the sentence text
                const newLocation = sentenceText.indexOf(wordText, currentPosition);
                const newLength = wordText.length;

                if (newLocation !== -1) {
                    // Update the mapping's range
                    mapping.range.location = newLocation;
                    mapping.range.length = newLength;

                    // Move the current position pointer
                    currentPosition = newLocation + newLength;
                } else {
                    console.warn(`Word "${wordText}" not found in sentence: "${sentenceText}"`);
                }

                // Save the corrected mapping
                correctedMappings[key] = mapping;
            }

            // Replace the old mappings with corrected ones
            sentence.mappings = correctedMappings;
        });

        return model;
    }

    const onSelectMapping = async (mapping) => {
        if (groupMode) {
            setSelectedGroupMappings(mapping);
            return;
        }

        setSelectedMapping({ mapping: mapping, story: fixedModel })
        setResultSnippets(null)

        if (!mapping) {
            return
        }
        console.log('onSelectMapping', mapping)

        let learningLanguageCode = learningLanguage?.code


        if (!learningLanguageCode) {
            console.log('return')
            return
        }

        try {
            // console.log(`load ${word} ${wordType} ${learningLanguageCode}`)
            setIsLoadingSnippet(true)

            let definitions = fixedModel.selected_definitions?.[userLanguage.code] ?? {}
            let snippets = await loadStorySnippets(mapping, definitions, learningLanguageCode, userLanguage.code, t)

            setResultSnippets(snippets)
        } catch (error) {
            console.error(error)
            setResultSnippets(null)
        } finally {
            setIsLoadingSnippet(false)
        }
    }

    function onAddStory() {

        let data = JSON.parse(newStoryText);
        let story = data;

        // // Example usage:

        // for (let sentenceKey in story.sentences) {
        //     let sentence = story.sentences[sentenceKey];
        //     if (sentence.mappings) {
        //         for (let mappingKey in sentence.mappings) {
        //             let mapping = sentence.mappings[mappingKey];
        //             mapping.key = mappingKey;
        //         }
        //         sentence.mappings = Object.values(sentence.mappings);
        //     }
        // }

        setIsFixingModel(true)
        const fixedModel = fixModel(copyJSONObject(story)).then((fixedModel) => {
            setFixedModel(fixedModel);
            console.log('Fixed model ' + JSON.stringify(fixedModel, null, 2));
            setFixedModelText(JSON.stringify(fixedModel, null, 2));
            toast({
                title: "Success",
                description: "Story parsed successfully. Click on the copy button to copy the fixed model.",
                status: "success",
                duration: 10000,
                isClosable: true,
            })
        }).catch((error) => {
            console.error('fix model error: ' + error)
            toast({
                title: "Error parsing story",
                description: "There was an error parsing the story: " + error,
                status: "error",
                duration: 10000,
                isClosable: true,
            })
        })
            .finally(() => {
                setIsFixingModel(false)
            })
        console.log('FixedModel: ' + JSON.stringify(fixedModel, null, 2));

    }

    useEffect(() => {
        if (fixedModel) {
            try {
                let fixedModelCopy = copyJSONObject(fixedModel);
                // convert every mappings array to a dictionary
                // for (let sentenceKey in fixedModelCopy.sentences) {
                //     let sentence = fixedModelCopy.sentences[sentenceKey];
                //     if (sentence.mappings) {
                //         let mappingsDict = {}
                //         sentence.mappings.forEach((mapping) => {
                //             mappingsDict[mapping.key] = mapping
                //         })
                //         sentence.mappings = mappingsDict
                //     }
                // }
                setFixedModelCopyData(fixedModelCopy)
            } catch (error) {
                console.error('Error copying fixed model: ' + error)
            }
        } else {
            setFixedModelCopyData(null)
        }

    }, [fixedModel])

    function onUploadStory() {
        if (fixedModelCopyData) {
            console.log(storyLanguage.code + ' Model to upload: ' + JSON.stringify(fixedModelCopyData, null, 2))
            try {
                setIsUploadingStory(true)
                let result = uploadStory(fixedModelCopyData, storyLanguage.code)
                console.log('Upload story result ' + JSON.stringify(result, null, 2))
                toast({
                    title: "Success",
                    description: "Story uploaded successfully",
                    status: "success",
                    duration: 10000,
                    isClosable: true,
                })
            } catch (error) {
                toast({
                    title: "Error uploading story",
                    description: "There was an error uploading the story: " + error,
                    status: "error",
                    duration: 10000,
                    isClosable: true,
                })
            } finally {
                setIsUploadingStory(false)
            }
        }
    }

    function onCombineIntoGroup() {
        let minIndex = -1
        let maxIndex = -1

        let keys = []

        let modelCopy = copyJSONObject(fixedModel)

        for (let mapping of selectedGroupMappings) {
            let firstCharacterIndex = mapping.range.location
            let lastCharacterIndex = mapping.range.location + mapping.range.length

            if (minIndex === -1 || firstCharacterIndex < minIndex) {
                console.log('minIndex is now ' + firstCharacterIndex)
                minIndex = firstCharacterIndex
            }

            if (maxIndex === -1 || lastCharacterIndex > maxIndex) {
                console.log('maxIndex is now ' + lastCharacterIndex)
                maxIndex = lastCharacterIndex
            }

            keys.push(mapping.id)
        }

        if (minIndex !== -1 && maxIndex !== -1) {
            let sentenceToFix = null
            console.log('keys to remove: ' + JSON.stringify(keys, null, 2))
            // for(let key in keys) {
            // remove from mappings array where .key property === key
            for (let sentence of modelCopy.sentences) {
                console.log('Pre filter mappings ' + JSON.stringify(sentence.mappings))
                let newMappings = sentence.mappings.filter((mapping) => !keys.includes(mapping.id))
                console.log('Filtered new mappings ' + JSON.stringify(newMappings))
                if (!sentenceToFix && newMappings.length !== sentence.mappings.length) {
                    console.log('sentenceToFix is ' + JSON.stringify(sentence, null, 2))
                    sentenceToFix = sentence
                    console.log('Assigning newMappings ' + JSON.stringify(newMappings) + ' instead of ' + JSON.stringify(sentence.mappings))
                    sentence.mappings = newMappings
                }

            }
            // }

            let combinedSnippets = []
            for (let mapping of selectedGroupMappings) {

                for (let snippet of mapping.snippets) {
                    let copy = copyJSONObject(snippet)
                    combinedSnippets.push(copy)
                }
                // delete copy.template
                // delete copy.range
                // delete copy.key
                // delete copy.other_snippets

                // let mappingOtherSnippets = mapping.other_snippets ?? {}
                // for (let key in mappingOtherSnippets) {
                //     otherSnippets[key] = copyJSONObject(mappingOtherSnippets[key])
                // }

                // otherSnippets[random8ID()] = copy
            }

            let combinedMapping = {
                id: random8ID(),
                text: sentenceToFix.text.slice(minIndex, maxIndex),
                word_type: "proper_noun", // hardcoded for now 
                range: {
                    location: minIndex,
                    length: maxIndex - minIndex
                },
                snippets: combinedSnippets
            }

            sentenceToFix.mappings.push(combinedMapping)
            sentenceToFix.mappings = sentenceToFix.mappings.sort((a, b) => a.range.location - b.range.location)
            console.log('fixed model after combining: ' + JSON.stringify(modelCopy, null, 2))

            setFixedModel(copyJSONObject(modelCopy))
        } else {
            console.log('Failed, indices are ' + minIndex + ' ' + maxIndex)
        }

        setSelectedGroupMappings([])
        setGroupMode(false)
    }

    function onEnterGroupMode() {
        setGroupMode(true)
    }

    function tryUpdateFixedModel() {
        try {
            let data = JSON.parse(fixedModelText);
            setFixedModel(data);
            toast({
                title: "Success",
                description: "Fixed model updated successfully",
                status: "success",
                duration: 10000,
                isClosable: true,
            })
        } catch (error) {
            toast({
                title: "Error parsing fixed model",
                description: "There was an error parsing the fixed model",
                status: "error",
                duration: 10000,
                isClosable: true,
            })
        }
    }

    return (
        <>
            <Box
                data-test="add-story-page"
                h="100vh"
                flexDirection="column"
                style={{
                    backgroundImage: "url('/icons/bg.png')",
                    backgroundSize: "cover",
                    backgroundRepeat: "repeat-y",
                    backgroundPosition: "center center",
                }}
            >

                <Header />
                {storyLanguage && <ChooseLanguageComponent preSelectedLanguage={storyLanguage} onChangeLanguage={(code) => {
                    let nextLanguage = LANGUAGES.find((lang) => lang.code === code)
                    console.log('OnChange language: ' + code + ' ' + JSON.stringify(nextLanguage))
                    setStoryLanguage(nextLanguage)
                }} />
                }

                {isFixingModel && <Spinner color='white' />}

                {fixedModel && <Box>
                    {groupMode && <Button isDisabled={selectedGroupMappings.length === 0} onClick={() => { onCombineIntoGroup() }} >Combine into group</Button>}
                    {!groupMode && <Button onClick={() => { onEnterGroupMode() }}>Group items</Button>}
                    <CopyToClipboardButton text={JSON.stringify(fixedModelCopyData, null, 2)} />
                    {/* <Textarea color='white' rows={10} value={fixedModelText} onChange={(e) => { setFixedModelText(e.target.value) }} />
                    <Button mb={2} onClick={() => {
                        tryUpdateFixedModel()
                    }}>Update Fixed Model</Button> */}
                </Box>
                }

                {!fixedModel && <Box>
                    <Textarea color='white' rows={10} value={newStoryText} onChange={(e) => { setNewStoryText(e.target.value) }} />

                    <Button onClick={() => {
                        onAddStory()
                    }}>Add Story</Button>
                </Box>
                }


                {fixedModel && <Box data-test='main-box' h='calc(100% - 128px)'>
                    <Box display="flex" height="100%" >
                        <Box flex="7" p="4" h='100%'>
                            {/* Content for the 70% width panel */}
                            <StoryDetails adminMode={groupMode} story={fixedModel} onSelectMapping={onSelectMapping} />
                        </Box>
                        <Divider h='100%' opacity={0.2} orientation="vertical" />
                        <Box flex="3" p="4" h='100%' overflowY="auto">
                            {/* Content for the 30% width panel */}
                            {isLoadingSnippet && <Box w='100%'>
                                <Center>
                                    <Spinner color='gray' />
                                </Center>
                            </Box>}
                            {resultSnippets && <DictionarySearchResults hideCollapseSnippet={false} highlightedText={selectedMapping?.mapping?.text?.toLowerCase()} results={resultSnippets} classroomID={null} showsCreateButton={false} />
                            }
                        </Box>
                    </Box>
                    <Button mb={2} isLoading={isUploadingStory} onClick={() => {
                        onUploadStory()
                    }}>Upload Story</Button>
                </Box>
                }
                <Footer />
            </Box>
        </>
    );
}

export default AddStory;

